import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import SubmitButton from 'Lib/form/SubmitButton';
import { Formik } from 'formik';
import { TextField } from 'Lib/form/TextField';
import useUpdateCompanyBranchMutation from 'App/hooks/useUpdateCompanyBranchMutation';
import * as Yup from 'yup';

const propTypes = {
    branch: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
    onHide: PropTypes.func.isRequired,
};

const defaultProps = {};

const validationSchema = Yup.object().shape({
    name: Yup.string().required().label('Name'),
});

function EditBranchForm({ branch, onHide }) {
    const updateBranchMutation = useUpdateCompanyBranchMutation(branch.id);

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{
                name: branch.name,
            }}
            onSubmit={(values) => {
                return updateBranchMutation.mutateAsync(values).then(onHide);
            }}
        >
            {({ handleSubmit }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal.Body>
                            <TextField fieldName="name" required label="Name" />
                        </Modal.Body>

                        <Modal.Footer>
                            <FormButtonGroup>
                                <Button type="button" bsStyle="default" onClick={onHide}>
                                    Cancel
                                </Button>
                                <SubmitButton>Save</SubmitButton>
                            </FormButtonGroup>
                        </Modal.Footer>
                    </form>
                );
            }}
        </Formik>
    );
}

EditBranchForm.propTypes = propTypes;
EditBranchForm.defaultProps = defaultProps;

export default EditBranchForm;
