import React, { useCallback, useState } from 'react';
import ActionCell, { ActionButtonLink } from 'App/components/styles/ActionCell';
import { generatePath, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import CompanyService from 'App/services/CompanyService';
import { queryClient } from 'Lib/queryClient';
import * as R from 'ramda';
import { viewEditUserRoute } from 'App/common/routeLookup';
import DisableUserModal from '../../../components/DisableUserModal';

const propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

const defaultProps = {};

function UserActionsCell({ row }) {
    const [showDisableModal, setShowDisableModal] = useState(false);

    const user = row.original;

    const closeModal = useCallback(() => {
        setShowDisableModal(false);
    }, []);

    const handleDelete = useCallback(() => {
        return CompanyService.disableCompanyUser(user.id)
            .then(closeModal)
            .then(() => {
                /**
                 * Since the change happens without a page redirect (remounting the compoonent), we need to move the
                 * user from the "active" to the "disabled" cache stores so that the change takes effect immediately.
                 */
                queryClient.setQueryData(['company', 'users'], R.reject(R.propEq(user.id, 'id')));
                queryClient.setQueryData(['company', 'users', 'disabled'], R.append(user));
                /**
                 * Then, we need will clear out any of the other caches related to the users so that refetches will
                 * occur.
                 */
                queryClient.removeQueries(['company', 'users']);
            });
    }, [closeModal, user]);

    return (
        <>
            <ActionCell>
                <Link to={generatePath(viewEditUserRoute, { userId: row.original.id })}>Edit</Link>
                <ActionButtonLink onClick={() => setShowDisableModal(true)}>Disable</ActionButtonLink>
            </ActionCell>

            <DisableUserModal
                onHide={() => {
                    setShowDisableModal(false);
                }}
                show={showDisableModal}
                onDelete={handleDelete}
            />
        </>
    );
}

UserActionsCell.propTypes = propTypes;
UserActionsCell.defaultProps = defaultProps;

export default UserActionsCell;
