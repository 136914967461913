import React from 'react';
import PropTypes from 'prop-types';
import { generatePath, Link } from 'react-router-dom';
import { viewDisabledUserRoute, viewEditUserRoute } from 'App/common/routeLookup';

const propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }).isRequired,
    value: PropTypes.string.isRequired,
    isFormDisabled: PropTypes.bool,
};

const defaultProps = {
    isFormDisabled: false,
};

function UserLinkCell({ value, row, isFormDisabled }) {
    const user = row.original;

    const path = isFormDisabled ? viewDisabledUserRoute : viewEditUserRoute;
    return <Link to={generatePath(path, { userId: user.id })}>{value}</Link>;
}

UserLinkCell.propTypes = propTypes;
UserLinkCell.defaultProps = defaultProps;

export default UserLinkCell;
