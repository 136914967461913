import React from 'react';
import { TOGGLE_LABEL_PLACEMENT } from 'Lib/form/ToggleSwitchField';
import { useFormikContext } from 'formik';
import ToggleSwitch from 'App/components/ToggleSwitch';
import ToggleLabel from 'App/components/ToggleSwitch/ToggleLabel';
import LabelText from 'App/components/ToggleSwitch/LabelText';
import * as R from 'ramda';
import addBranchAdminToRow from '../utils/addBranchAdminToRow';
import getApplyToAllPrivs from '../utils/getApplyToAllPrivs';
import usePrivsForAll from '../usePrivsForAll';
import PropTypes from 'prop-types';

const propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.string.isRequired,
            isEnabled: PropTypes.bool.isRequired,
            name: PropTypes.string.isRequired,
        }),
    }).isRequired,
};

const defaultProps = {};

function EnableCell({ row }) {
    const { values, setValues } = useFormikContext();
    const { original } = row;
    const { id, isEnabled, name } = original;

    const privsForAll = usePrivsForAll();

    const fieldId = `toggle-branch-${id}`;

    const label = `${isEnabled ? 'Disable' : 'Enable'} ${name}`;

    return (
        <ToggleLabel>
            <ToggleSwitch
                disabled={values.isFormDisabled}
                id={fieldId}
                name={fieldId}
                aria-label={label}
                checked={isEnabled}
                onChange={(value) => {
                    //prettier-ignore
                    R.compose(
                        setValues,
                        /**
                         * Add all other privs for apply to all if requried
                         */
                        getApplyToAllPrivs(privsForAll),
                        /**
                         * Add branch admin to new branch if required
                         */
                        addBranchAdminToRow(value, id),
                        /**
                         * Add the branch
                         */
                        R.over(R.lensProp('branches'),
                            R.compose(
                                R.ifElse(
                                    R.always(value),
                                    R.append(original),
                                    R.reject(R.propEq(id, 'id'))
                                ),
                            )
                        ),
                        R.assoc('enableAllBranches', false)
                    )(values);
                }}
            />

            <LabelText placement={TOGGLE_LABEL_PLACEMENT.left}>Enable</LabelText>
        </ToggleLabel>
    );
}

EnableCell.propTypes = propTypes;
EnableCell.defaultProps = defaultProps;

export default EnableCell;
