function serializeJQLike(data, parentKey = '', params = new URLSearchParams()) {
    Object.entries(data).forEach(([key, value]) => {
        const newKey = parentKey ? `${parentKey}[${key}]` : key;
        if (typeof value === 'object' && value !== null) {
            serializeJQLike(value, newKey, params);
        } else {
            params.append(newKey, value || '');
        }
    });
    return params;
}

export default serializeJQLike;
