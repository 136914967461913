import React from 'react';
import BranchesActionsCell from './BranchesActionsCell';

function branchActionsRenderer(setBranchId) {
    return (props) => {
        return <BranchesActionsCell {...props} setBranchId={setBranchId} />;
    };
}

export default branchActionsRenderer;
