import createAxios from 'App/utils/createAxios';
import convertKeysToCamelCase from 'App/utils/keyConversion/convertKeysToCamelCase';

import config from '../components/config/config';
import getSlsBasePath from '../utils/getSlsBasePath/getSlsBasePath';

const { protocol, host, port } = window.location;
const therigyURL = `${protocol}//${host}${port ? `:${port}` : ''}`;
const phpRequest = createAxios({
    baseURL: `${therigyURL}/app.api.php/api`,
});

const slsHost = getSlsBasePath(config, 'integration');
const integrationSlsBaseUrl = `${slsHost}/${config.slsVersion}`;
const slsintegration = createAxios({
    baseURL: integrationSlsBaseUrl,
});

/**
 * get therapy list with respect to medication
 * @returns therapy list
 */
function getPdxPatient(pdxId) {
    return phpRequest.get(`/patient/pdx/${pdxId}`).then(convertKeysToCamelCase);
}

/**
 * get pdx patient details
 * @returns patient data
 */
function syncSlsPdxPatient(pdxId) {
    return slsintegration.post(`/migratePdxPatient`, { pdxPatientId: pdxId }).then(convertKeysToCamelCase);
}

export { getPdxPatient, syncSlsPdxPatient };
