import styled from '@emotion/styled';

const ToggleLabel = styled('label')`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 0.2rem;

    .label-text {
        margin-left: 0.5rem;

        &.label-placement-left {
            margin-left: 0;
            margin-right: 0.5rem;
        }
    }

    .form-button-group & {
        margin-bottom: 0;
        margin-right: 1em;

        &:last-child {
            margin-right: 0;
        }
    }
`;

export default ToggleLabel;
