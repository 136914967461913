import axios from 'axios';

import createAxios from 'App/utils/createAxios';
import { getData } from 'App/utils';
import serializeJQLike from 'App/utils/serializeJQLike';

const { protocol, host, port } = window.location;
const therigyURL = `${protocol}//${host}${port ? `:${port}` : ''}`;
const phpRequest = createAxios(
    {
        baseURL: `${therigyURL}/app.api.php/api`,
    },
    { shouldShowToastFn: () => false }
);

export function autocomplete(inputText) {
    if (!inputText) return;
    return phpRequest.get(`/user/physicians/like/${inputText}`);
}

export function getPhysician(physicianId) {
    return axios.get(`/ajax/api.physician.php?id=${physicianId}`).then(getData);
}

export function updatePhysician(params) {
    return axios.post(`/ajax/api.physician.php`, null, {
        params: serializeJQLike(params),
    });
}
