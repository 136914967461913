import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import useQueryParamTabs from 'App/hooks/useQueryParamTabs';
import Users from './Users';
import styled from '@emotion/styled';
import DisabledUsers from './DisabledUsers';
import Branches from './Branches';

const propTypes = {};

const defaultProps = {};

const paramTabConfig = {
    validTabs: ['users', 'disabled', 'branches'],
    defaultTab: 'users',
};

const StyledTabs = styled(Tabs)`
    .tab-pane {
        margin-top: 2rem;
    }
`;

function UserManagerTabs() {
    const { defaultActiveKey, setCurrentTab } = useQueryParamTabs(paramTabConfig);

    return (
        <StyledTabs defaultActiveKey={defaultActiveKey} id="user-manager-tabs" onSelect={setCurrentTab}>
            <Tab eventKey="users" title="Users">
                <Users />
            </Tab>
            <Tab eventKey="disabled" title="Disabled Users">
                <DisabledUsers />
            </Tab>
            <Tab eventKey="branches" title="Branches">
                <Branches />
            </Tab>
        </StyledTabs>
    );
}

UserManagerTabs.propTypes = propTypes;
UserManagerTabs.defaultProps = defaultProps;

export default UserManagerTabs;
