import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Alert, Grid } from 'react-bootstrap';
import { FaCheck, FaInfoCircle } from 'react-icons/fa';
import * as R from 'ramda';
import { supportWebsite } from 'App/components/config/config';
import checkFeatureFlagStatus from 'App/components/FeatureFlags/checkFeatureFlagStatus';
import PageWrapper from 'App/components/styles/PageWrapper/PageWrapper';
import useFetchDevFeatureFlagQuery from 'App/hooks/useFetchDevFeatureFlagQuery';
import useFetchPatient from 'App/hooks/useFetchPatient';
import { getPdxPatient, syncSlsPdxPatient } from 'App/services/PdxPatientService';
import PESService from 'App/services/PESService';
import hasLength from 'App/utils/hasLength';
import { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';
import convertKeysToSnakeCase from 'App/utils/keyConversion/convertKeysToSnakeCase';

const propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};
const defaultProps = {
    children: null,
};

const PDX_STATES = Object.freeze({
    INIT: 'INIT',
    SEARCHING: 'SEARCHING',
    ERROR: 'ERROR',
    OUT_OF_SYNC: 'OUT_OF_SYNC',
    SUCCESS: 'SUCCESS',
});

const fieldsToImportFromPdx = [
    'addressLine1',
    'addressLine2',
    'birthDate',
    'city',
    'country',
    'email',
    'ethnicity',
    'externalId',
    'firstName',
    'gender',
    'homePhone',
    'languageSpoken',
    'lastName',
    'maritalStatus',
    'middleName',
    'mobilePhone',
    'state',
    'zip',
];

const PatientPdxSync = ({ children }) => {
    const { patientId } = useParams();
    const { data: flags } = useFetchDevFeatureFlagQuery();
    const { data: patientData, isLoading, refetch } = useFetchPatient(patientId);
    const [pdxState, setPdxState] = useState(PDX_STATES.INIT);

    const isServerlessRouteEnabled = checkFeatureFlagStatus({
        flags,
        featureFlagName: PERMISSION_TYPES.ENABLE_PDX_SLS,
    });

    const handleError = (error) => {
        if (error?.response?.status === 404) {
            setPdxState(PDX_STATES.OUT_OF_SYNC);
        } else {
            setPdxState(PDX_STATES.ERROR);
        }
    };

    useEffect(() => {
        if (!isLoading && patientData?.pdx?.rxcomId) {
            setPdxState(PDX_STATES.SEARCHING);

            if (isServerlessRouteEnabled) {
                syncSlsPdxPatient(patientData.pdx.rxcomId)
                    .then(() => {
                        setPdxState(PDX_STATES.SUCCESS);
                    })
                    .catch(handleError);
            } else {
                getPdxPatient(patientData.pdx.rxcomId)
                    .then(
                        R.compose(
                            R.chain(R.assoc('addressLine1'), R.prop('AddressLine1')),
                            R.chain(R.assoc('addressLine2'), R.prop('AddressLine2'))
                        )
                    )
                    .then((res) => {
                        const resPick = R.pick(fieldsToImportFromPdx, res); //?
                        const patientPick = R.pick(fieldsToImportFromPdx, patientData); //?

                        const diff = R.compose(
                            R.map(R.prop(0)),
                            R.difference(R.toPairs(resPick)),
                            R.toPairs
                        )(patientPick);

                        if (hasLength(diff)) {
                            // post changes if there are changes
                            const payload = R.compose(
                                R.omit(['addressLine1', 'addressLine2']),
                                R.chain(R.assoc('address_line_1'), R.prop('addressLine1')),
                                R.chain(R.assoc('address_line_2'), R.prop('addressLine2')),
                                convertKeysToSnakeCase,
                                R.pick(diff)
                            )(resPick);

                            return PESService.updatePatientPhp(patientId, payload)
                                .then(() => {
                                    return refetch();
                                })
                                .then(() => {
                                    setPdxState(PDX_STATES.SUCCESS);
                                });
                        }
                    })
                    // handle failure
                    .catch(handleError);
            }
        }
    }, [isLoading, isServerlessRouteEnabled, patientData, patientId, refetch]);

    const dismissAlert = () => {
        setPdxState(PDX_STATES.INIT);
    };

    return (
        <>
            {pdxState === PDX_STATES.INIT ? null : (
                <PageWrapper style={{ minHeight: 'unset', paddingBottom: 0 }}>
                    <Grid>
                        {R.cond([
                            [
                                R.equals(PDX_STATES.SEARCHING),
                                () => (
                                    <Alert
                                        style={{
                                            background: 'unset',
                                            color: 'unset',
                                            border: 'unset',
                                        }}
                                        onDismiss={dismissAlert}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <FaInfoCircle style={{ marginRight: '.5rem' }} />
                                            <span> Synchronizing with Rx.com ...</span>
                                        </div>
                                    </Alert>
                                ),
                            ],
                            [
                                R.equals(PDX_STATES.ERROR),
                                () => (
                                    <Alert bsStyle="danger" onDismiss={dismissAlert}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <FaInfoCircle style={{ marginRight: '.5rem' }} />
                                            <span>
                                                There was an issue synchronizing with Rx.com. Please try refreshing the
                                                page. If this issue persists, please{' '}
                                                <a href={supportWebsite} rel="noopener noreferrer" target="_blank">
                                                    contact support
                                                </a>
                                                .
                                            </span>
                                        </div>
                                    </Alert>
                                ),
                            ],
                            [
                                R.equals(PDX_STATES.OUT_OF_SYNC),
                                () => (
                                    <Alert bsStyle="danger" onDismiss={dismissAlert}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <FaInfoCircle style={{ marginRight: '.5rem' }} />
                                            <span>
                                                Patient record out of sync with Rx.com. Please
                                                <a href="http://knowledge.therigy.com/course/view.php?id=31">
                                                    {' '}
                                                    click here{' '}
                                                </a>
                                                to learn how to resync this record. If this issue persists, please
                                                <a href={supportWebsite} rel="noopener noreferrer" target="_blank">
                                                    {' '}
                                                    contact support
                                                </a>
                                                .
                                            </span>
                                        </div>
                                    </Alert>
                                ),
                            ],
                            [
                                R.equals(PDX_STATES.SUCCESS),
                                () => (
                                    <Alert bsStyle="success" onDismiss={dismissAlert}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <FaCheck style={{ marginRight: '.5rem' }} />
                                            <span>Successfully synchronized with Rx.com</span>
                                        </div>
                                    </Alert>
                                ),
                            ],
                            [R.T, R.always(null)],
                        ])(pdxState)}
                    </Grid>
                </PageWrapper>
            )}

            {children}
        </>
    );
};

PatientPdxSync.defaultProps = defaultProps;
PatientPdxSync.propTypes = propTypes;

export default PatientPdxSync;
