import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import * as R from 'ramda';
import LaddaButton from '@/app/components/LaddaButton';

const propTypes = {
    children: PropTypes.node,
    ignoreDirty: PropTypes.bool,
    ignoreValid: PropTypes.bool,
    /**
     * Override the disable logic and force button to be disabled.
     */
    disabled: PropTypes.bool,
};

const defaultProps = {
    ignoreDirty: false,
    ignoreValid: false,
    disabled: false,
    children: 'Save'
};

function SubmitButton({ children, ignoreDirty, ignoreValid, ...props }) {
    const formik = useFormikContext();

    const isDisabled = R.anyPass([
        () => !ignoreDirty && !formik.dirty,
        () => !ignoreValid && !formik.isValid,
        () => formik.isSubmitting,
    ])();

    return (
        <LaddaButton
            {...props}
            type="submit"
            bsStyle="primary"
            disabled={isDisabled || props.disabled}
            isLoading={formik.isSubmitting}
        >
            {children}
        </LaddaButton>
    );
}

SubmitButton.propTypes = propTypes;
SubmitButton.defaultProps = defaultProps;

export default SubmitButton;
