import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { useUserContext } from 'App/contexts/UserContext';
import useFetchPatientIdentifiers from 'App/hooks/useFetchPatientIdentifiers';
import useFetchPatientOtherMedications from 'App/hooks/useFetchPatientOtherMedications';
import useFetchPatientPrescriptions from 'App/hooks/useFetchPatientPrescriptions';
import { dateOnlySlashesFormat, formatUtcDate } from 'App/services/DateService';
import PESService from 'App/services/PESService';
import { branchSettingsPropType, patientPropType } from 'Lib/types';
import useFetchMedications from '../hooks/useFetchMedications';
import useFetchPatientAllergies from '../hooks/useFetchPatientAllergies';
import { medicationAssociationPropType, questionPropType } from '../types';
import EhrEncounterNoteQuestion from './EhrEncounterNoteQuestion';

const ReviewSectionStyle = styled.div`
    margin-top: 1.5em;
`;

const EhrEncounterReviewContent = ({ patient, allQuestions, branchSettings, medicationAssociationsList }) => {
    const { values } = useFormikContext();
    const user = useUserContext();

    const { data: patientIdentifiers } = useFetchPatientIdentifiers(patient.id);
    const primaryIdentifier = (!patientIdentifiers ? [] : Object.values(patientIdentifiers)).find(
        (i) => i.identifierType === branchSettings.ehrPrimaryIdType
    );
    const patientPrimaryIdentifier = primaryIdentifier?.identifier;

    const activityDateQuestion = Object.values(values).find((questionValue) => questionValue.name === 'activity_date');

    const { data: prescriptions } = useFetchPatientPrescriptions(patient.id);
    const prescriptionsList = !prescriptions ? [] : Object.values(prescriptions).filter((p) => !!p.physician);

    const { data: medications } = useFetchMedications(patient.id);

    const { data: otherMedications = [] } = useFetchPatientOtherMedications(patient.id);
    const otherMedicationsList = Object.values(otherMedications);

    const { data: allergies = [] } = useFetchPatientAllergies(patient.id);

    return (
        <div>
            <div>
                Completed on {dayjs().format('YYYY/MM/DD HH:mm:ss')}, by {user.first_name} {user.last_name}
                {user.suffix && `, ${user.suffix}`}
            </div>

            <ReviewSectionStyle>
                <strong>Patient</strong>
                <div>
                    Patient Name: {patient.lastName}, {patient.firstName} {patient.middleName?.charAt(0).toUpperCase()}
                </div>
                <div>DOB: {formatUtcDate({ date: patient.birthDate, format: dateOnlySlashesFormat })}</div>
                <div data-testid="patient-ehr-id">EHR ID: {patientPrimaryIdentifier}</div>
            </ReviewSectionStyle>

            <ReviewSectionStyle>
                <strong>Activity Date </strong>
                {dayjs(activityDateQuestion?.response).format('YYYY/MM/DD')}
            </ReviewSectionStyle>

            <ReviewSectionStyle>
                <strong>Providers </strong>
                {!prescriptionsList.length ? (
                    '--'
                ) : (
                    <ul id="ehr-provider-list">
                        {prescriptionsList.map((p) => (
                            <li key={p.id}>
                                {p.physician.firstName} {p.physician.lastName}
                            </li>
                        ))}
                    </ul>
                )}
            </ReviewSectionStyle>

            <ReviewSectionStyle>
                <strong>Activity Medications </strong>
                {!medicationAssociationsList.length ? (
                    '--'
                ) : (
                    <ul>
                        {medicationAssociationsList.map((med) => (
                            <li key={med.id}>{med.displayName || med.medication.medicationName}</li>
                        ))}
                    </ul>
                )}
            </ReviewSectionStyle>

            <ReviewSectionStyle>
                <strong>Encounter Notes</strong>
                <div>
                    {patient.firstName} {patient.lastName} is a {PESService.getAge(patient.birthDate)}-year-old{' '}
                    {patient.gender} being followed by the clinical pharmacist for medication monitoring of{' '}
                    {!medications
                        ? '--'
                        : Object.values(medications)
                              .map((med) => med.therapy.name)
                              .join(', ')}
                    . This patient&apos;s current med list, allergies, and vaccination status have been reviewed and
                    updated as appropriate.
                </div>
            </ReviewSectionStyle>

            <ReviewSectionStyle>
                <strong>Other Medications </strong>
                {!otherMedicationsList.length ? (
                    '--'
                ) : (
                    <ul>
                        {otherMedicationsList.map((otherMedication) => (
                            <li key={otherMedication.id}>{otherMedication.text}</li>
                        ))}
                    </ul>
                )}
            </ReviewSectionStyle>

            <ReviewSectionStyle>
                <strong>Allergies </strong>
                {!allergies.length ? (
                    '--'
                ) : (
                    <ul>
                        {allergies.map((allergy) => (
                            <li key={allergy}>{allergy}</li>
                        ))}
                    </ul>
                )}
            </ReviewSectionStyle>

            <ReviewSectionStyle>
                <strong>Care Details</strong>
                {Object.values(allQuestions).map((question) => (
                    <EhrEncounterNoteQuestion
                        key={question.questionId}
                        question={question}
                        patient={patient}
                        branchSettings={branchSettings}
                    />
                ))}
            </ReviewSectionStyle>

            <ReviewSectionStyle>
                <strong>Identified Concerns </strong>
                {!values.newSummaryNoteClinicalIssues?.length ? (
                    '--'
                ) : (
                    <ul>
                        {values.newSummaryNoteClinicalIssues.map((clinicalIssue) => (
                            <li key={clinicalIssue.valueName}>{clinicalIssue.valueName}</li>
                        ))}
                    </ul>
                )}
            </ReviewSectionStyle>

            <ReviewSectionStyle>
                <strong>Summary Notes</strong>
                <div>{values.newSummaryNote || '--'}</div>
            </ReviewSectionStyle>
        </div>
    );
};

EhrEncounterReviewContent.propTypes = {
    patient: patientPropType.isRequired,
    allQuestions: PropTypes.objectOf(questionPropType).isRequired,
    branchSettings: branchSettingsPropType.isRequired,
    medicationAssociationsList: PropTypes.arrayOf(medicationAssociationPropType).isRequired,
};

export default EhrEncounterReviewContent;
