import React from 'react';
import { CheckboxField } from 'Lib/form/CheckboxField';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';
import { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';
import { setWorkQueueFilterGenerator } from '../../workQueue.utils';
import { useFormikContext } from 'formik';

const PredictiveModelFilter = () => {
    const patientPredictiveModelEnabled = useIsPermitted(PERMISSION_TYPES.PATIENT_PREDICTIVE_MODEL);
    const {
        values: { filterParams },
        setFieldValue,
    } = useFormikContext();
    const setWorkQueueFilter = setWorkQueueFilterGenerator(setFieldValue);

    return (
        patientPredictiveModelEnabled && (
            <div className="filter form-group">
                <fieldset>
                    <legend className="control-label filter-header">Predictive Model Filter</legend>
                    <CheckboxField
                        id="predictiveModelRisk"
                        fieldName="filterParams.predictiveModelRisk"
                        label="Predictive Model Patient"
                        onChange={(e) =>
                            setWorkQueueFilter(filterParams, 'predictiveModelRisk', e.target.checked, true)
                        }
                    />
                </fieldset>
            </div>
        )
    );
};

export default PredictiveModelFilter;
