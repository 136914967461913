const patientRoute = '/patient/:patientId';
const editPatientRoute = '/editPatient/:patientId';
export const addPatientRoute = '/addPatient';
export const unassignedPatientRoute = '/unassignedPatient/:associationQueueId';
const activityRoute = '/patient/:patientId/activity/:activityId';
const assessmentPreviewRoute = '/assessment/:assessmentId/preview';
const contentMonographRoute = '/content-monograph/:label';
const workQueueRoute = '/workQueue';
const assignmentManagerRoute = '/assignment-manager';
const referralBaseRouteRegex = '/patient/:patientId/referral(-[^/]+)?';
const referralBaseRoute = '/patient/:patientId/referral';
const referralManagerRoute = `${referralBaseRoute}-manager`;
const referralDetailRoute = `${referralBaseRoute}/:referralId`;
const referralBenefitsInvestigation = `${referralDetailRoute}/benefits-investigation/:benefitsInvestigationId/type/:typeId`;
const referralPriorAuthorization = `${referralDetailRoute}/prior-authorization/:priorAuthorizationId/type/:typeId`;
const referralFinancialAssistance = `${referralDetailRoute}/financial-assistance/:financialAssistanceId/type/:typeId`;
const referralAppealRoute = `${referralDetailRoute}/appeal/:activityId/type/:typeId`;
const queueManagerRoute = '/queue-manager';
const userManagerRoute = '/user-manager';
const createUserRoute = '/user/new';
const enableUserRoute = '/user/:userId/enable';
const viewDisabledUserRoute = '/user/:userId/disabled';
const viewEditUserRoute = '/user/:userId';

export {
    activityRoute,
    assessmentPreviewRoute,
    assignmentManagerRoute,
    contentMonographRoute,
    createUserRoute,
    editPatientRoute,
    enableUserRoute,
    patientRoute,
    queueManagerRoute,
    referralAppealRoute,
    referralBaseRouteRegex,
    referralBenefitsInvestigation,
    referralDetailRoute,
    referralFinancialAssistance,
    referralManagerRoute,
    referralPriorAuthorization,
    userManagerRoute,
    viewDisabledUserRoute,
    viewEditUserRoute,
    workQueueRoute,
};
