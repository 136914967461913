import React from 'react';
import EditUser from './EditUser';
import PropTypes from 'prop-types';

const propTypes = {
    isEnabling: PropTypes.bool,
};

const defaultProps = {
    isEnabling: false,
};

function ViewDisabledUser({ isEnabling }) {
    return <EditUser isUserDisabled isEnabling={isEnabling} />;
}

ViewDisabledUser.propTypes = propTypes;
ViewDisabledUser.defaultProps = defaultProps;

export default ViewDisabledUser;
