import styled from '@emotion/styled';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';

import { DatePickerField } from 'Lib/form/DatePickerField';
import { NumberField } from 'Lib/form/NumberField';

import CommonPatientFieldWrapper from '../CommonPatientFieldWrapper';

const ButtonContainer = styled.div`
    visibility: hidden;
`;

const RxFillFormStyle = styled.div`
    display: flex;

    &:hover ${ButtonContainer} {
        visibility: visible;
    }
`;

const RxFillForm = ({ fieldPrefix, onClickRemove }) => {
    const { values } = useFormikContext();

    return (
        <RxFillFormStyle>
            <Row>
                <CommonPatientFieldWrapper fieldName="fillNumber">
                    {(commonProps) => (
                        <Col md={3}>
                            <NumberField
                                {...commonProps}
                                fieldName={`${fieldPrefix}.${commonProps.fieldName}`}
                                label="Fill Number"
                                placeholder="Fill Number"
                                min={0}
                            />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                <CommonPatientFieldWrapper fieldName="fillDate">
                    {(commonProps) => (
                        <Col md={3}>
                            <DatePickerField
                                {...commonProps}
                                fieldName={`${fieldPrefix}.${commonProps.fieldName}`}
                                label="Fill Date"
                                placeholder="YYYY/MM/DD"
                            />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                <CommonPatientFieldWrapper fieldName="dispensedQuantity">
                    {(commonProps) => (
                        <Col md={3}>
                            <NumberField
                                {...commonProps}
                                fieldName={`${fieldPrefix}.${commonProps.fieldName}`}
                                label="Dispensed Qty"
                                placeholder="Dispensed Qty"
                                min={1}
                            />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                <CommonPatientFieldWrapper fieldName="daysSupply">
                    {(commonProps) => (
                        <Col md={3}>
                            <NumberField
                                {...commonProps}
                                fieldName={`${fieldPrefix}.${commonProps.fieldName}`}
                                label="Days Supply"
                                placeholder="Days Supply"
                                min={1}
                            />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
            </Row>
            <ButtonContainer>
                <Button
                    bsStyle="link"
                    style={{ visibility: get(values, `${fieldPrefix}.id`) ? 'hidden' : undefined }}
                    onClick={onClickRemove}
                    aria-label="Remove Rx Fill"
                >
                    <FaTimes />
                </Button>
            </ButtonContainer>
        </RxFillFormStyle>
    );
};

RxFillForm.propTypes = {
    fieldPrefix: PropTypes.string.isRequired,
    onClickRemove: PropTypes.func.isRequired,
};

export default RxFillForm;
