import React from 'react';
import PropTypes from 'prop-types';
import DeleteModal from 'App/components/DeleteModal';

const propTypes = {
    onHide: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    show: PropTypes.bool,
};

const defaultProps = {
    show: false,
};

function DisableUserModal({ onHide, show, onDelete }) {
    return show ? (
        <DeleteModal
            onHide={onHide}
            show
            title="Disable User"
            confirmText="Disable"
            message="Are you sure you want to disable this user?"
            onDelete={onDelete}
        />
    ) : null;
}

DisableUserModal.propTypes = propTypes;
DisableUserModal.defaultProps = defaultProps;

export default DisableUserModal;
