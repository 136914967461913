import React, { useMemo } from 'react';
import useFetchBranchesQuery from 'App/hooks/useFetchBranchesQuery';
import { CheckboxField } from 'Lib/form/CheckboxField';
import { CheckboxMultiField } from 'Lib/form/CheckboxMultiField';
import { useFormikContext } from 'formik';
import { sortBy } from 'lodash';
import { setWorkQueueFilterGenerator } from '../../workQueue.utils';

const BranchFilter = () => {
    const { data: branchList } = useFetchBranchesQuery();
    const {
        values: { filterParams },
        setFieldValue,
    } = useFormikContext();
    const { branches, allBranches } = filterParams;
    const setWorkQueueFilter = setWorkQueueFilterGenerator(setFieldValue);

    const options = useMemo(() => {
        return sortBy(
            Object.values(branchList || {}).map((opt) => ({ id: opt.id, option: opt.name })),
            'option'
        );
    }, [branchList]);

    return (
        <div className="filter form-group">
            <fieldset>
                <legend className="control-label filter-header">Branch</legend>
                <CheckboxField
                    fieldName="filterParams.allBranches"
                    label="All Branches"
                    onChange={(e) => setWorkQueueFilter(filterParams, 'allBranches', e.target.checked, true)}
                />
                {!allBranches && (
                    <CheckboxMultiField
                        fieldName="filterParams.branches"
                        value={branches}
                        options={options}
                        onChange={(value) => setWorkQueueFilter(filterParams, 'branches', value, true)}
                    />
                )}
            </fieldset>
        </div>
    );
};

export default BranchFilter;
