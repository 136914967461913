import React, { useCallback, useMemo, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as R from 'ramda';
import * as Yup from 'yup';
import { TextField } from 'Lib/form/TextField';
import SelectField from 'Lib/form/SelectField';
import BranchTable from './BranchTable';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import CancelButton from 'Lib/form/CancelButton';
import SubmitButton from 'Lib/form/SubmitButton';
import RolesField from './RolesField';
import PropTypes from 'prop-types';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import { generatePath, useHistory } from 'react-router-dom';
import CompanyAdminSelect from './CompanyAdminSelect';
import ErrorMessage from 'App/common/formik/ErrorMessage';
import CompanyService from 'App/services/CompanyService';
import { queryClient } from 'Lib/queryClient';
import ResetButton from 'Lib/form/ResetButton';
import createUser from './utils/createUser';
import enableUser from './utils/enableUser';
import updateUser from './utils/updateUser';
import getIsFieldDisabled from './utils/getIsFieldDisabled';
import { viewDisabledUserRoute } from 'App/common/routeLookup';
import DisableUserModal from '../DisableUserModal';

const propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        isDisabled: PropTypes.bool,
        isFormDisabled: PropTypes.bool,
    }),
};

const defaultProps = {
    user: null,
};

const defaultInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    suffix: '',
    branches: [],
    isCompanyAdmin: false,
    roles: [],
};

const schema = Yup.object().shape({
    firstName: Yup.string().max(40).trim().required().label('First Name'),
    lastName: Yup.string().max(40).trim().required().label('Last Name'),
    email: Yup.string()
        .max(90)
        .trim()
        .email('Please enter a valid email address.')
        .required('Please enter a valid email address.')
        .label('Email'),
    suffix: Yup.string().max(10).trim().label('Title/Credentials'),
    isCompanyAdmin: Yup.boolean().label('Enable as STM Admin'),
    roles: Yup.array().min(1, 'A user must have at least one role.').required().label('Roles'),
    branches: Yup.array()
        .min(1, 'A user must be assigned to at least one branch.')
        .of(
            Yup.object().shape({
                privileges: Yup.array()
                    .min(1, 'At least one permission is required for any enabled branch.')
                    .required()
                    .label('Permissions'),
            })
        ),
});

function UserForm({ user }) {
    const [showDisableModal, setShowDisableModal] = useState(false);

    const initialValues = useMemo(() => {
        if (user) {
            return user;
        }
        return defaultInitialValues;
    }, [user]);

    const history = useHistory();

    const isReportingEnabled = useIsPermitted(PERMISSION_TYPES.REPORT_ENGINE);

    const hasUser = !!user;

    const goToUserManagerPage = useCallback(() => {
        queryClient.removeQueries(['company', 'users']);
        history.push('/user-manager');
    }, [history]);

    const goToDisabledUserPage = useCallback(() => {
        if (user?.id) {
            queryClient.removeQueries(['company', 'users']);
            history.push(generatePath(viewDisabledUserRoute, { userId: user.id }));
        }
    }, [history, user?.id]);

    return (
        <Row>
            <Col md={12}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={(values) => {
                        if (!hasUser) {
                            return createUser(values).then(goToUserManagerPage);
                        }

                        if (user.isDisabled) {
                            return enableUser(values).then(goToUserManagerPage);
                        }

                        return updateUser(values).then(goToUserManagerPage);
                    }}
                >
                    {({ handleSubmit, errors }) => (
                        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                            <Row>
                                <Col md={6} sm={12}>
                                    <TextField
                                        disabled={getIsFieldDisabled(hasUser, 'firstName', user)}
                                        fieldName="firstName"
                                        label="First Name"
                                        placeholder="First Name"
                                        required
                                    />

                                    <TextField
                                        disabled={getIsFieldDisabled(hasUser, 'lastName', user)}
                                        fieldName="lastName"
                                        label="Last Name"
                                        placeholder="Last Name"
                                        required
                                    />

                                    <TextField
                                        fieldName="email"
                                        label="Email"
                                        placeholder="Email"
                                        required
                                        disabled={getIsFieldDisabled(hasUser, 'email', user)}
                                    />

                                    <TextField
                                        fieldName="suffix"
                                        label="Title/Credentials"
                                        placeholder="Title/Credentials"
                                        disabled={user?.isFormDisabled}
                                    />

                                    <RolesField />

                                    <h3>Company Permissions</h3>

                                    <CompanyAdminSelect />

                                    {isReportingEnabled ? (
                                        <SelectField
                                            isDisabled={user?.isFormDisabled}
                                            placeholder="No Reporting"
                                            fieldName="reporting"
                                            label="Reporting"
                                            isClearable
                                            options={[
                                                { value: 'Report Builder', label: 'Report Builder' },
                                                { value: 'Report Viewer', label: 'Report Viewer' },
                                            ]}
                                        />
                                    ) : null}
                                </Col>
                            </Row>

                            <h3>Branch Permissions</h3>
                            <p>
                                Only branches with an active license will display in the table. If a branch is missing,
                                please contact your Customer Success Specialist to update.
                            </p>

                            {errors.branches && !R.is(Array, errors.branches) ? (
                                <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                    <ErrorMessage name="branches" />
                                </div>
                            ) : null}

                            <BranchTable />

                            <FormButtonGroup>
                                {hasUser && user.isDisabled && user.isFormDisabled ? (
                                    <Button
                                        type="button"
                                        onClick={() => {
                                            history.push(`/user/${user.id}/enable`);
                                        }}
                                    >
                                        Enable
                                    </Button>
                                ) : null}
                                {hasUser && !user.isDisabled ? (
                                    <Button
                                        type="button"
                                        bsStyle="danger"
                                        onClick={() => {
                                            setShowDisableModal(true);
                                        }}
                                    >
                                        Disable
                                    </Button>
                                ) : null}
                                <CancelButton onClick={() => history.push('/user-manager')} />
                                {hasUser && !user.isDisabled ? <ResetButton /> : null}
                                <SubmitButton />
                            </FormButtonGroup>
                        </form>
                    )}
                </Formik>

                <DisableUserModal
                    onHide={() => {
                        setShowDisableModal(false);
                    }}
                    show={showDisableModal}
                    onDelete={() => {
                        return CompanyService.disableCompanyUser(user.id).then(goToDisabledUserPage);
                    }}
                />
            </Col>
        </Row>
    );
}

UserForm.propTypes = propTypes;
UserForm.defaultProps = defaultProps;

export default UserForm;
