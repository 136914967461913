import React from 'react';
import useFetchRolesQuery from 'App/hooks/useFetchRolesQuery';
import SelectField from 'Lib/form/SelectField';
import * as R from 'ramda';
import { useFormikContext } from 'formik';

const propTypes = {};

const defaultProps = {};

function RolesField() {
    const { values } = useFormikContext();
    const { isLoading, data } = useFetchRolesQuery();

    return (
        <SelectField
            required
            disabled={values.isFormDisabled}
            isLoading={isLoading}
            options={data || []}
            fieldName="roles"
            label="Roles"
            isMulti
            isClearable
            getOptionLabel={R.prop('roleName')}
            getOptionValue={R.prop('roleId')}
        />
    );
}

RolesField.propTypes = propTypes;
RolesField.defaultProps = defaultProps;

export default RolesField;
