import React from 'react';
import ActionCell, { ActionButtonLink } from 'App/components/styles/ActionCell';
import PropTypes from 'prop-types';

const propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    setBranchId: PropTypes.func.isRequired,
};

const defaultProps = {};

function BranchesActionsCell({ setBranchId, row }) {
    const { id } = row.original;

    return (
        <ActionCell>
            <ActionButtonLink onClick={() => setBranchId(id)}>Edit</ActionButtonLink>
        </ActionCell>
    );
}

BranchesActionsCell.propTypes = propTypes;
BranchesActionsCell.defaultProps = defaultProps;

export default BranchesActionsCell;
