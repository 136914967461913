import * as R from 'ramda';
import hasLength from 'App/utils/hasLength';
import getBranchAdminPrivilege from './getBranchAdminPrivilege';

function getPrivileges(values) {
    const privileges = R.compose(
        R.propOr([], 'privileges'),
        R.find(R.compose(hasLength, R.prop('privileges')))
    )(values.branches);

    if (values.isCompanyAdmin) {
        return getBranchAdminPrivilege(privileges);
    }

    return privileges;
}

export default getPrivileges;
