import { useQuery } from 'react-query';
import CompanyService from 'App/services/CompanyService';

function useFetchCompanyUser(userId, disabledOnly = false) {
    return useQuery(['company', 'users', userId], {
        queryFn: () => CompanyService.getCompanyUser(userId, disabledOnly),
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchCompanyUser;
