import mapValuesForSubmit from './mapValuesForSubmit';
import CompanyService from 'App/services/CompanyService';
import * as R from 'ramda';

function enableUser(values) {
    const { branches, roles, privileges } = mapValuesForSubmit(values);

    return CompanyService.enableCompanyUser(values.id, branches)
        .then(() => {
            return CompanyService.updateCompanyUser(
                values.id,
                R.pick(['suffix', 'firstName', 'email', 'lastName'], values)
            );
        })
        .then(() => {
            return CompanyService.updateCompanyUserRoles(values.id, roles);
        })
        .then(() => {
            return Promise.all(
                privileges.map((priv) => {
                    return CompanyService.updateCompanyUserPrivileges(values.id, priv.branchId, priv);
                })
            );
        });
}

export default enableUser;
