import mapValuesForSubmit from './mapValuesForSubmit';
import CompanyService from 'App/services/CompanyService';
import * as R from 'ramda';

function updateUser(values) {
    const { branches, roles, privileges } = mapValuesForSubmit(values);
    return CompanyService.updateCompanyUser(
        values.id,
        R.pick(['suffix', 'firstName', 'email', 'lastName'], values)
    ).then(() => {
        return CompanyService.updateCompanyUserBranches(values.id, branches)
            .then(() => {
                return CompanyService.updateCompanyUserRoles(values.id, roles);
            })
            .then(() => {
                return Promise.all(
                    privileges.map((priv) => {
                        return CompanyService.updateCompanyUserPrivileges(values.id, priv.branchId, priv);
                    })
                );
            });
    });
}

export default updateUser;
