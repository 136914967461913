import * as R from 'ramda';
import getDisabledBranches from './getDisabledBranches';

/**
 *
 * @param branchList
 * @param isEnabled
 * @param values
 * @returns {*}
 */
function getEnableAllPrivs(branchList, isEnabled, values) {
    const branchesToAdd = getDisabledBranches(branchList, values);

    return R.compose(
        R.ifElse(
            R.always(isEnabled),
            //prettier-ignore
            R.over(
                R.lensProp('branches'),
                R.concat(branchesToAdd),
            ),
            R.assoc('branches', [])
        )
    )(values);
}

export default R.curry(getEnableAllPrivs);
