import React from 'react';
import ToggleSwitchField from 'Lib/form/ToggleSwitchField';
import { useFormikContext } from 'formik';
import * as R from 'ramda';
import usePrivsForAll from '../usePrivsForAll';
import getEnableAllPrivs from '../utils/getEnableAllPrivs';
import getApplyToAllPrivs from '../utils/getApplyToAllPrivs';
import PropTypes from 'prop-types';

const propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            isEnabled: PropTypes.bool.isRequired,
            name: PropTypes.string.isRequired,
        })
    ),
};
const defaultProps = {
    data: [],
};

function EnableAllBranchesFilter({ data }) {
    const { values, setValues } = useFormikContext();

    const privsForAll = usePrivsForAll();

    return (
        <ToggleSwitchField
            disabled={values.isFormDisabled}
            fieldName="enableAllBranches"
            label="Enable All"
            onChange={(value) => {
                //prettier-ignore
                R.compose(
                    setValues,
                    getApplyToAllPrivs(privsForAll),
                    getEnableAllPrivs(data, value),
                    R.assoc('enableAllBranches', value)
                )(values);
            }}
        />
    );
}

EnableAllBranchesFilter.propTypes = propTypes;
EnableAllBranchesFilter.defaultProps = defaultProps;

export default EnableAllBranchesFilter;
