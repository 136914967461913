import React from 'react';
import Page from 'App/components/Page';
import './AssignmentManagerPage.scss';
import { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';
import { AssignmentMainSection } from './Components/AssignmentMainSection';
import { QueueProvider } from '../WorkQueue/QueueProvider';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';
import { NotFound } from 'App/components/errors';
import ActivityCount from '../WorkQueue/components/ActivityCount';
import MyFilters from '../WorkQueue/components/MyFilters';
import WorkQueueFilters from '../WorkQueue/components/WorkQueueFilters';

export const AssignmentManagerPage = () => {
    const isAssignmentManager = useIsPermitted(PERMISSION_TYPES.ASSIGNMENT_MANAGER);

    if (isAssignmentManager) {
        return (
            <QueueProvider isAssignmentManager>
                <div className="assignment-manager">
                    <aside>
                        <ActivityCount />
                        <MyFilters />
                        <WorkQueueFilters isAssignmentManager />
                    </aside>
                    <article>
                        <Page
                            title="Assignment Manager"
                            showPageTitle
                            permissionType={PERMISSION_TYPES.ENABLE_REACT_ASSIGNMENT_MANAGER}
                        >
                            <AssignmentMainSection />
                        </Page>
                    </article>
                </div>
            </QueueProvider>
        );
    }
    return <NotFound />;
};

export default AssignmentManagerPage;
