import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import { TextInput } from 'Lib/form/TextField';
import useSaveWorkQueueFilterMutation from '../hooks/useSaveWorkQueueFilterMutation';
import { transformReactFilterToSave } from '../workQueue.utils';
import { useFormikContext } from 'formik';
import SessionStorageService from 'App/services/SessionStorageService';
import * as R from 'ramda';

const propTypes = {
    onHide: PropTypes.func.isRequired,
};

function SaveFilterModal({ onHide }) {
    const saveFilterMutation = useSaveWorkQueueFilterMutation();
    const [savedFilterName, setSavedFilterName] = useState('');

    const {
        values: { filterParams },
        setFieldValue,
    } = useFormikContext();

    const saveFilter = () => {
        saveFilterMutation
            .mutateAsync({ filter: transformReactFilterToSave(filterParams), name: savedFilterName })
            .then((res) => {
                setSavedFilterName('');
                setFieldValue('activeSavedFilter', res[res.length - 1]?.id);
                SessionStorageService.setOnUser('workQueue_activeSavedFilter', res[res.length - 1]?.id);
                onHide(true);
            });
    };

    return (
        <Modal show onHide={onHide} backdrop="static">
            <Modal.Header>
                <Modal.Title>Save Filter</Modal.Title>
            </Modal.Header>
            <form>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <TextInput
                                name="savedFilterName"
                                label="Name"
                                placeholder="Name"
                                value={savedFilterName}
                                onChange={(e) => setSavedFilterName(e.target.value)}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <FormButtonGroup>
                        <Button onClick={onHide}>Cancel</Button>
                        <Button
                            bsStyle="primary"
                            disabled={!savedFilterName || R.pipe(R.trim, R.isEmpty)(savedFilterName)}
                            onClick={saveFilter}
                        >
                            Save
                        </Button>
                    </FormButtonGroup>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

SaveFilterModal.propTypes = propTypes;

export default SaveFilterModal;
