import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { RadioField } from 'Lib/form/RadioField';
import { camelCase } from 'lodash';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { DatePickerField } from 'Lib/form/DatePickerField';
import { dateRangeOptions } from '../../workQueue.constant';
import { setWorkQueueFilterGenerator } from '../../workQueue.utils';

const DateRangeFilter = () => {
    const history = useHistory();
    const location = useLocation();
    const { values, setFieldValue } = useFormikContext();
    const {
        result: { activityCountResult },
        filterParams,
    } = values;
    const setWorkQueueFilter = setWorkQueueFilterGenerator(setFieldValue);

    const options = useMemo(() => {
        return dateRangeOptions.map((range) => {
            const activityCount = activityCountResult[camelCase(range.value)];
            return {
                id: range.value,
                option: `${range.name} ${activityCount >= 0 ? `(${activityCount})` : ''}`,
            };
        });
    }, [activityCountResult]);

    useEffect(() => {
        if (filterParams.type) {
            history.push(
                generatePath(location.pathname, {
                    dateFilter: filterParams.type,
                })
            );
        }
    }, [filterParams.type, history, location.pathname]);

    return (
        <>
            <div className="filter form-group">
                <RadioField
                    fieldName="filterParams.type"
                    label="Date Range"
                    options={options}
                    onChange={(e) => setWorkQueueFilter(filterParams, 'type', e.target.value, true)}
                />
            </div>
            {values.filterParams.type === 'custom' && (
                <>
                    <DatePickerField
                        fieldName="filterParams.dateStart"
                        label="Date Range Start"
                        placeholder="YYYY/MM/DD"
                        onChange={(date) => setWorkQueueFilter(filterParams, 'dateStart', date, true)}
                        maxDate={filterParams.dateEnd}
                    />
                    <DatePickerField
                        fieldName="filterParams.dateEnd"
                        label="Date Range End"
                        placeholder="YYYY/MM/DD"
                        onChange={(date) => setWorkQueueFilter(filterParams, 'dateEnd', date, true)}
                        minDate={filterParams.dateStart}
                    />
                </>
            )}
        </>
    );
};

export default DateRangeFilter;
