import { mapKeys, snakeCase } from 'lodash';
import convertKeysToCamelCase from 'App/utils/keyConversion/convertKeysToCamelCase';
import { toDate } from 'App/services/DateService';
import SessionStorageService from 'App/services/SessionStorageService';
import dayjs from 'Lib/dayjs';
import * as R from 'ramda';

export const processWorkQueueResult = (records) => {
    return records.map((record) => ({
        ...convertKeysToCamelCase(record),
        patientName: `${record.patientLastName}, ${record.patientFirstName}`,
        highRisk: record.high_risk === '1',
    }));
};

/**
 * The dates used in the filter expect the YYYY-MM-DD format. And when the search is performed on the back-end, it will
 * concatenate that value with time values of "00:00:00" nad "23:59:59", respectively.
 * @param date
 * @returns {*}
 */
export const workQueueFormatDate = (date) => {
    if (date) {
        return dayjs(date).format('YYYY-MM-DD');
    }
    return date;
};

export const setWorkQueueFilterGenerator =
    (setFieldValue) =>
    (currentState, filterName, filterValue, clearActiveSavedFilter = false) => {
        const updatedFilter = {
            ...currentState,
            [filterName]: filterValue,
            page: 1,
        };
        setFieldValue('filterParams', updatedFilter);
        if (clearActiveSavedFilter) {
            setFieldValue('activeSavedFilter', null);
            SessionStorageService.setOnUser('workQueue_activeSavedFilter', null);
        }
    };

const convertSearchFilter = (search) => mapKeys(search, (value, key) => snakeCase(key));

export const updateWorkQueueFilters = (filters) => {
    return R.compose(
        R.over(R.lensProp('dateStart'), workQueueFormatDate),
        R.over(R.lensProp('dateEnd'), workQueueFormatDate),
        R.over(R.lensProp('search'), convertSearchFilter)
    )(filters);
};

const transformFilterToReactCommon = (input = {}) => {
    const output = {
        search: {
            humanId: input?.filter_human_id ?? '',
            externalId: input?.filter_external_id ?? '',
            ehrId: input?.filter_ehr_id ?? '',
            otherId: input?.filter_other_id ?? '',
            patientName: input?.filter_patient_name ?? '',
            medication: input?.filter_medication ?? '',
            medicationAssociation: input?.filter_medication_association ?? '',
            dateDueSearch: input?.filter_date_due_search ?? '',
            activityName: input?.filter_activity_name ?? '',
            patientDob: input?.filter_patient_dob ?? '',
            activityStatus: input?.filter_activity_status ?? '',
            activityStatusDate: input?.filter_activity_status_date ?? '',
            updatedName: input?.filter_updated_name ?? '',
            assignedTo: input?.filter_assigned_to ?? '',
            patientLabels: input?.filter_patient_labels ?? '',
            needsBySearch: input?.filter_needs_by_search ?? '',
            branchName: input?.filter_branch_name ?? '',
            patientState: input?.filter_patient_state ?? '',
            patientStatus: input?.filter_patient_status ?? '',
            insurancePlanName: input?.filter_insurance_plan_name ?? '',
        },
        dateEnd: toDate(input?.dateEnd),
        dateStart: toDate(input?.dateStart),
        type: input?.dateRange || 'this_week',
        branches: Object.keys(input?.branches || {}),
        highRisk: input?.highRisk,
        onlyMine: input?.onlyMine,
        allTypes: input?.all_types,
        categories: Object.keys(input?.group_ids || {}),
        allBranches: input?.all_branches,
        assignedToMe: input?.assignedToMe,
        filters: Object.keys(input?.activityTypes || {}),
        predictiveModelRisk: input?.predictiveModelRisk,
        allCategories: input?.all_categories,
    };
    return output;
};

export const transformDbFilterToReactState = (input) => {
    const output = { ...transformFilterToReactCommon(input), page: 1, count: 25, sorting: {} };
    return output;
};

export const transformLsFilterToReact = (input = {}) => {
    const output = {
        ...transformFilterToReactCommon(input?.filter),
        page: input?.page || 1,
        count: input?.count || 25,
        sorting: input?.sorting || {},
    };
    return output;
};

const transformFilterToSaveCommon = (input) => {
    const output = {
        activityTypes: input.filters.reduce((acc, filter) => {
            acc[filter] = true;
            return acc;
        }, {}),
        all_branches: input.allBranches,
        all_categories: input.allCategories,
        all_types: input.allTypes,
        assignedToMe: input.assignedToMe,
        branches: input.branches.reduce((acc, branch) => {
            acc[branch] = true;
            return acc;
        }, {}),
        dateRange: input.type,
        dateEnd: input.dateEnd,
        dateStart: input.dateStart,
        filter_activity_name: input?.search?.activityName ?? '',
        filter_activity_status: input?.search?.activityStatus ?? '',
        filter_activity_status_date: input?.search?.activityStatusDate ?? '',
        filter_assigned_to: input?.search?.assignedTo ?? '',
        filter_branch_name: input?.search?.branchName ?? '',
        filter_patient_state: input?.search?.patientState ?? '',
        filter_patient_status: input?.search?.patientStatus ?? '',
        filter_insurance_plan_name: input?.search?.insurancePlanName ?? '',
        filter_date_due_search: input?.search?.dateDueSearch ?? '',
        filter_human_id: input?.search?.humanId ?? '',
        filter_external_id: input?.search?.externalId ?? '',
        filter_ehr_id: input?.search?.ehrId ?? '',
        filter_other_id: input?.search?.otherId ?? '',
        filter_medication: input?.search?.medication ?? '',
        filter_medication_association: input?.search?.medicationAssociation ?? '',
        filter_needs_by_search: input?.search?.needsBySearch ?? '',
        filter_patient_dob: input?.search?.patientDob ?? '',
        filter_patient_labels: input?.search?.patientLabels ?? '',
        filter_patient_name: input.search.patientName ?? '',
        filter_updated_name: input?.search?.updatedName ?? '',
        group_ids: input.categories.reduce((acc, category) => {
            acc[category] = true;
            return acc;
        }, {}),
        highRisk: input.highRisk,
        onlyMine: input.onlyMine,
        search: '',
        predictiveModelRisk: input?.search?.predictiveModelRisk ?? '',
    };

    return output;
};

export const transformReactFilterToSave = (input) => {
    const output = { ...transformFilterToSaveCommon(input) };

    return output;
};

export const transformReactFilterToLS = (input) => {
    const output = {
        page: input.page ?? 1,
        count: input.count ?? 25,
        filter: {
            ...transformFilterToSaveCommon(input),
        },
        sorting: input.sorting ?? {},
        group: {},
    };

    return output;
};
