import { useQuery } from 'react-query';
import CompanyService from 'App/services/CompanyService';

function useFetchCompanyBranchQuery(branchId) {
    return useQuery(['company', 'branches', branchId], {
        queryFn: () => CompanyService.getCompanyBranch(branchId),
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchCompanyBranchQuery;
