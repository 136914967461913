import styled from '@emotion/styled';
import { Formik } from 'formik';
import { get, isEqual } from 'lodash';
import { prop } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';

import FlexCenter from 'App/components/styles/FlexCenter';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import PageTitle from 'App/components/styles/PageTitle/PageTitle';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner';
import { useUserContext } from 'App/contexts/UserContext';
import { saveFieldOrder } from 'App/services/QueueManagerService';
import ResetButton from 'Lib/form/ResetButton';
import SelectField from 'Lib/form/SelectField';
import SubmitButton from 'Lib/form/SubmitButton';
import ToggleSwitchField from 'Lib/form/ToggleSwitchField';
import { queryClient } from 'Lib/queryClient';
import toast from 'Lib/toast';
import QueueManagerTable from './components/QueueManagerTable';
import { defaultFieldOptions, fieldOptionsLookup, maxNumColumns } from './constants';
import useFetchFieldOrder from './hooks/useFetchFieldOrder';

const QueueManagerStyle = styled.div`
    padding: 20px;
`;

const QueueManagerTableHeaderStyle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const convertQueueFieldOrderToView = (fieldOrders) => {
    return {
        fieldOrders: fieldOrders?.map((fieldOrder) => ({
            ...fieldOrder,
            field_order: fieldOrder.field_order.map((branchFieldOrder) => ({
                ...branchFieldOrder,
                field_selected: {
                    value: branchFieldOrder.field_selected,
                    label: fieldOptionsLookup[branchFieldOrder.field_selected],
                },
            })),
        })),
        saveAll: false,
    };
};

const arePathsEqual = (obj1, obj2, paths) => paths.every((path) => isEqual(get(obj1, path), get(obj2, path)));

const QueueManager = () => {
    const user = useUserContext();
    const { data: fieldOrders, isFetching } = useFetchFieldOrder(user.active_company.ID);

    const initialValues = useMemo(() => {
        return {
            ...convertQueueFieldOrderToView(fieldOrders),
            selectedBranch: {
                division_name: user.active_branch.Name,
                division_id: user.active_branch.ID,
            },
        };
    }, [fieldOrders, user.active_branch.ID, user.active_branch.Name]);

    if (isFetching) {
        return (
            <FlexCenter style={{ flexGrow: 1 }}>
                <TherigyLoadingSpinner />
            </FlexCenter>
        );
    }

    return (
        <QueueManagerStyle>
            <PageTitle style={{ padding: 0 }}>Queue Manager</PageTitle>

            <Formik
                initialValues={initialValues}
                onSubmit={async (values, { resetForm }) => {
                    const branchFieldOrders = values.fieldOrders.find(
                        (fieldOrder) => fieldOrder.branch.division_id === values.selectedBranch.division_id
                    );
                    const payload = {
                        branch_id: branchFieldOrders.branch.division_id,
                        field_order: branchFieldOrders.field_order.map((branchFieldOrder) => ({
                            ...branchFieldOrder,
                            field_selected: branchFieldOrder.field_selected?.value,
                        })),
                        save_all: values.saveAll,
                    };
                    const newFieldOrders = await saveFieldOrder(user.active_company.ID, payload);

                    queryClient.setQueryData(['queueManager', 'fieldOrder', user.active_company.ID], newFieldOrders);
                    resetForm({
                        values: {
                            ...convertQueueFieldOrderToView(newFieldOrders),
                            selectedBranch: values.selectedBranch,
                        },
                    });

                    const successMsg = values.saveAll
                        ? 'The column order for all branches was saved successfully.'
                        : `The column order for branch ${branchFieldOrders.branch.division_name} was saved successfully.`;
                    toast.success(successMsg);
                }}
            >
                {({ handleSubmit, values, setFieldValue, validateForm }) => {
                    const fieldOrderIndex = values.fieldOrders.findIndex(
                        (fieldOrder) => fieldOrder.branch.division_id === values.selectedBranch.division_id
                    );
                    return (
                        <form onSubmit={handleSubmit}>
                            <div style={{ maxWidth: '30em' }}>
                                <SelectField
                                    fieldName="selectedBranch"
                                    label="Select Branch"
                                    options={values.fieldOrders.map((f) => f.branch)}
                                    getOptionLabel={prop('division_name')}
                                    getOptionValue={prop('division_id')}
                                />
                            </div>

                            <QueueManagerTableHeaderStyle>
                                <h4>{values.selectedBranch.division_name}</h4>

                                <Button
                                    onClick={() => {
                                        setFieldValue(
                                            `fieldOrders[${fieldOrderIndex}].field_order`,
                                            defaultFieldOptions
                                        );
                                        setImmediate(() => validateForm());
                                    }}
                                >
                                    Restore Default
                                </Button>
                            </QueueManagerTableHeaderStyle>

                            <QueueManagerTable />

                            <FormButtonGroup>
                                <Button
                                    onClick={() => {
                                        const newIndex = values.fieldOrders[fieldOrderIndex].field_order.length;
                                        setFieldValue(`fieldOrders[${fieldOrderIndex}].field_order[${newIndex}]`, {});
                                    }}
                                    disabled={values.fieldOrders[fieldOrderIndex].field_order.length >= maxNumColumns}
                                >
                                    Add Column
                                </Button>
                                <div>(Limit of {maxNumColumns})</div>

                                <div style={{ flexGrow: 1 }} />

                                <ToggleSwitchField fieldName="saveAll" label="Apply to all branches" />
                                <ResetButton>Reset</ResetButton>
                                <SubmitButton
                                    ignoreDirty
                                    disabled={arePathsEqual(values, initialValues, [
                                        'saveAll',
                                        `fieldOrders[${fieldOrderIndex}]`,
                                    ])}
                                >
                                    Save Changes
                                </SubmitButton>
                            </FormButtonGroup>
                        </form>
                    );
                }}
            </Formik>
        </QueueManagerStyle>
    );
};

export default QueueManager;
