import React from 'react';
import ToggleSwitchField from 'Lib/form/ToggleSwitchField';
import * as R from 'ramda';
import { useFormikContext } from 'formik';
import usePrivsForAll from '../usePrivsForAll';
import getApplyToAllPrivs from '../utils/getApplyToAllPrivs';

function ApplyToAllFilter() {
    const { values, setValues } = useFormikContext();

    const privsForAll = usePrivsForAll();

    return (
        <ToggleSwitchField
            disabled={values.isFormDisabled}
            fieldName="applyToAll"
            label="All selected branches use the same permissions"
            onChange={(value) => {
                //prettier-ignore
                R.compose(
                    setValues,
                    getApplyToAllPrivs(privsForAll),
                    R.set(R.lensProp('applyToAll'), value)
                )(values);
            }}
        />
    );
}

export default ApplyToAllFilter;
