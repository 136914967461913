import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import camelCase from 'lodash.camelcase';
import { TextInput } from 'Lib/form/TextField';
import * as R from 'ramda';

export const useGetWorkQueueColumns = ({ headerMapping, onFilterChange, searchState, isAssignmentManager }) => {
    const {
        values: {
            result: { workQueueTableHeaders },
            selectedRows = {},
        },
        setFieldValue,
    } = useFormikContext();

    const columns = useMemo(() => {
        if (!workQueueTableHeaders?.headers) {
            return [];
        }
        const headers = R.compose(
            R.sortBy(R.prop('fieldOrder')),
            /**
             * Double-reverse allows for the right-most duplicate to be retained and the left-most be dropped
             */
            R.reverse,
            R.uniqBy(R.prop('fieldSelected')),
            R.reverse
        )(workQueueTableHeaders?.headers || []);

        const customColumns = headers?.map((header) => {
            const mapping = headerMapping[header.fieldSelected];

            const accessor = mapping.accessor || camelCase(header.fieldSelected);
            const ariabLabel = `Filter by ${header.nameOfHeader}`;

            return {
                Header: header.nameOfHeader,
                accessor,
                sortName: mapping.sortName || header.fieldSelected,
                id: mapping.sortName || header.fieldSelected,
                canSort: mapping.canSort || false,
                Filter: (
                    <TextInput
                        name={mapping.filterName || header.fieldSelected}
                        placeholder={ariabLabel}
                        onChange={onFilterChange}
                        title={ariabLabel}
                        aria-label={ariabLabel}
                        value={searchState[mapping.filterName || header.fieldSelected] || ''}
                    />
                ),
                canFilter: true,
                ...(!isAssignmentManager && mapping.Cell ? { Cell: mapping.Cell } : {}),
            };
        });
        if (isAssignmentManager) {
            const selectionColumn = {
                id: 'selection',
                Header: ({ getToggleAllPageRowsSelectedProps, rows }) => {
                    const defaultProps = getToggleAllPageRowsSelectedProps();
                    return (
                        <div>
                            <input
                                type="checkbox"
                                {...defaultProps}
                                onChange={(e) => {
                                    defaultProps.onChange(e);
                                    if (e.target.checked) {
                                        setFieldValue(
                                            'selectedRows',
                                            rows.reduce(
                                                (selectedValues, row) => ({
                                                    ...selectedValues,
                                                    [row.original.activityId]: true,
                                                }),
                                                {}
                                            )
                                        );
                                    } else {
                                        setFieldValue('selectedRows', {});
                                    }
                                }}
                            />
                        </div>
                    );
                },
                Cell: ({ row }) => {
                    const defaultProps = row.getToggleRowSelectedProps();
                    return (
                        <div>
                            <input
                                type="checkbox"
                                {...defaultProps}
                                onChange={(e) => {
                                    defaultProps.onChange(e);
                                    const newState = { ...selectedRows };
                                    if (e.target.checked) {
                                        newState[row.original.activityId] = true;
                                    } else {
                                        delete newState[row.original.activityId];
                                    }
                                    setFieldValue('selectedRows', newState);
                                }}
                            />
                        </div>
                    );
                },
            };

            selectionColumn.Header.propTypes = {
                getToggleAllPageRowsSelectedProps: PropTypes.func.isRequired,
                rows: PropTypes.arrayOf(
                    PropTypes.shape({
                        original: PropTypes.shape({
                            activityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                        }).isRequired,
                    }).isRequired
                ).isRequired,
            };
            selectionColumn.Cell.propTypes = {
                row: PropTypes.shape({
                    getToggleRowSelectedProps: PropTypes.func.isRequired,
                    original: PropTypes.shape({
                        activityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                    }).isRequired,
                }).isRequired,
            };

            customColumns.unshift(selectionColumn);
        }
        return customColumns;
    }, [
        workQueueTableHeaders?.headers,
        isAssignmentManager,
        headerMapping,
        onFilterChange,
        searchState,
        setFieldValue,
        selectedRows,
    ]);

    return columns;
};
