import React from 'react';
import ToggleSwitchField from 'Lib/form/ToggleSwitchField';
import * as R from 'ramda';
import { useFormikContext } from 'formik';
import { BRANCH_ADMIN_PRIVILEGE_OPTION } from 'App/features/UserManager/constants';
import hasLength from 'App/utils/hasLength';

const propTypes = {};

const defaultProps = {};

function CompanyAdminSelect() {
    const { values, setValues } = useFormikContext();

    return (
        <ToggleSwitchField
            disabled={values.isFormDisabled}
            fieldName="isCompanyAdmin"
            label="Enable as Company Admin"
            onChange={(value) => {
                R.compose(
                    setValues,
                    R.ifElse(
                        R.always(value),
                        R.over(
                            R.lensProp('branches'),
                            R.compose(
                                R.map(
                                    R.over(
                                        R.lensProp('privileges'),
                                        R.ifElse(
                                            hasLength,
                                            R.compose(R.uniq, R.append(BRANCH_ADMIN_PRIVILEGE_OPTION)),
                                            R.always([BRANCH_ADMIN_PRIVILEGE_OPTION])
                                        )
                                    )
                                )
                            )
                        ),
                        R.identity
                    ),
                    R.set(R.lensProp('isCompanyAdmin'), value)
                )(values);
            }}
        />
    );
}

CompanyAdminSelect.propTypes = propTypes;
CompanyAdminSelect.defaultProps = defaultProps;

export default CompanyAdminSelect;
