import { useFormikContext } from 'formik';
import React from 'react';

import SafeHtmlOnly from 'App/components/SafeHtmlOnly/SafeHtmlOnly';
import { formatUtcDate, isoDateOnlyFormat } from 'App/services/DateService';
import { branchSettingsPropType, patientPropType } from 'Lib/types';
import AssessmentQuestionText from '../question/AssessmentQuestionText';
import { questionPropType } from '../types';

function isQuestionOrDescendentsEhr(question, values) {
    const children = values[question.questionId]?.children || [];
    return (
        question.attributes.ehr_encounter_note ||
        children.reduce((agg, child) => {
            return agg || isQuestionOrDescendentsEhr(child, values);
        }, false)
    );
}

const EhrEncounterNoteQuestion = ({ question, patient, branchSettings }) => {
    const { values } = useFormikContext();
    const questionValue = values[question.questionId];

    if (!isQuestionOrDescendentsEhr(question, values)) {
        return null;
    }

    if (branchSettings.ehrNoteHideUnanswered && !questionValue?.response) {
        return null;
    }

    function getFormattedResponse(response) {
        if (['checkbox', 'radio', 'select'].includes(question.type)) {
            const fullAnswer = question.answers[response];
            return fullAnswer?.option;
        }
        if (question.type === 'date') {
            return formatUtcDate({ date: response, format: isoDateOnlyFormat });
        }
        return response;
    }

    return (
        <ul data-testid={`ehr-question-${question.questionId}`}>
            <li>
                <strong>
                    <AssessmentQuestionText question={question} viewMode={values.viewMode} patient={patient} />
                </strong>
            </li>
            <ul>
                {[].concat(questionValue?.response).map((response) => {
                    const formattedResponse = getFormattedResponse(response);
                    return (
                        <li
                            key={`${question.questionId}-${response}`}
                            data-testid={`ehr-question-${question.questionId}-${response}`}
                        >
                            {formattedResponse ? <SafeHtmlOnly>{formattedResponse}</SafeHtmlOnly> : '<unanswered>'}
                            {(questionValue?.children || [])
                                .sort((a, b) => a.attributes.position - b.attributes.position)
                                .map((child) => (
                                    <EhrEncounterNoteQuestion
                                        key={child.questionId}
                                        question={child}
                                        patient={patient}
                                        branchSettings={branchSettings}
                                    />
                                ))}
                        </li>
                    );
                })}
            </ul>
        </ul>
    );
};

EhrEncounterNoteQuestion.propTypes = {
    question: questionPropType.isRequired,
    patient: patientPropType.isRequired,
    branchSettings: branchSettingsPropType.isRequired,
};

export default EhrEncounterNoteQuestion;
