import * as R from 'ramda';
import { DEFAULT_USER_PRIVIILEGES } from 'App/features/UserManager/constants';

function mapValuesForSubmit(values) {
    const roles = values.roles.map(R.pick(['roleId']));

    const { branches, privileges } = values.branches.reduce(
        (result, branch) => {
            result.branches.push({ id: branch.id });

            const privs = R.compose(
                R.assoc('branchId', branch.id),
                R.mergeRight(DEFAULT_USER_PRIVIILEGES),
                R.mapObjIndexed(R.T),
                R.indexBy(R.prop('value'))
            )(branch.privileges);

            if (values.isCompanyAdmin) {
                privs.companyAdmin = true;
            }

            if (values.reporting?.value === 'Report Builder') {
                privs.reportBuilder = true;
            }

            if (values.reporting?.value === 'Report Viewer') {
                privs.reportViewer = true;
            }

            result.privileges.push(privs);

            return result;
        },
        { branches: [], privileges: [] }
    );
    return {
        branches,
        roles,
        privileges,
    };
}

export default mapValuesForSubmit;
