import styled from '@emotion/styled';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';

import { NumberInput } from 'Lib/form/NumberField';

const AvailabilityTimepickerFieldStyle = styled.div`
    display: flex;

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        opacity: 1;
    }
`;

const AvailabilityTimepickerField = ({ fieldPrefix }) => {
    const { values, setFieldValue } = useFormikContext();
    const pickerDate = get(values, fieldPrefix);

    return (
        <AvailabilityTimepickerFieldStyle>
            <NumberInput
                name={`${fieldPrefix}.hour`}
                value={pickerDate.format('h')}
                onChange={(e) => {
                    const { value } = e.target;
                    const numValue = Number(value);
                    const adjValue = (numValue % 12) + (pickerDate.format('A') === 'PM' ? 12 : 0);
                    const newDateValue = pickerDate.hour(adjValue);
                    setFieldValue(fieldPrefix, newDateValue);
                }}
            />
            <NumberInput
                name={`${fieldPrefix}.minute`}
                value={pickerDate.format('mm')}
                onChange={(e) => {
                    const { value } = e.target;
                    const newDateValue = pickerDate.minute(value);
                    setFieldValue(fieldPrefix, newDateValue);
                }}
                step={15}
            />
            <Button
                onClick={() => {
                    const newDateValue = pickerDate.add(12, 'hour');
                    setFieldValue(fieldPrefix, newDateValue);
                }}
            >
                {pickerDate.format('A')}
            </Button>
        </AvailabilityTimepickerFieldStyle>
    );
};

AvailabilityTimepickerField.propTypes = {
    fieldPrefix: PropTypes.string.isRequired,
};

export default AvailabilityTimepickerField;
