import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    children: PropTypes.node.isRequired,
    placement: PropTypes.oneOf(['left', 'right']).isRequired,
};

const defaultProps = {};

function LabelText({ children, placement }) {
    return <span className={`label-text label-placement-${placement}`}>{children}</span>;
}

LabelText.propTypes = propTypes;
LabelText.defaultProps = defaultProps;

export default LabelText;
