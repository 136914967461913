import PropTypes from 'prop-types';
import React from 'react';

import AvailabilityTimepickerField from './AvailabilityTimepickerField';

const AvailabilityTimepickerRange = ({ fieldPrefix }) => {
    return (
        <>
            <AvailabilityTimepickerField fieldPrefix={`${fieldPrefix}.startTime`} />
            <AvailabilityTimepickerField fieldPrefix={`${fieldPrefix}.endTime`} />
        </>
    );
};

AvailabilityTimepickerRange.propTypes = {
    fieldPrefix: PropTypes.string.isRequired,
};

export default AvailabilityTimepickerRange;
