import React from 'react';
import Page from 'App/components/Page';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import UserForm from '../components/UserForm';

const propTypes = {};

const defaultProps = {};

function CreateUser() {
    return (
        <Page showPageTitle title="Add User" permissionType={PERMISSION_TYPES.USER_MANAGER}>
            <UserForm />
        </Page>
    );
}

CreateUser.propTypes = propTypes;
CreateUser.defaultProps = defaultProps;

export default CreateUser;
