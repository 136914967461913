import {useQuery} from 'react-query';
import CompanyService from "App/services/CompanyService";
import * as R from 'ramda'

function useFetchRolesQuery() {
    return useQuery(['company', 'roles' ], {
        queryFn: () => CompanyService.getCompanyRoles().then(R.map(R.applySpec({
            roleId: R.prop('id'),
            roleName: R.prop('name'),
        }))),
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchRolesQuery;
