import { useQuery } from 'react-query';

import { autocomplete } from 'App/services/PhysicianService';
import toast from 'Lib/toast';

function useFetchPhysicianAutocomplete(inputValue) {
    return useQuery(['physicianAutocomplete', inputValue], () => autocomplete(inputValue), {
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        staleTime: 60 * 1000,
        onError: () => {
            toast.error('Prescriber not found');
        },
    });
}

export default useFetchPhysicianAutocomplete;
