import * as R from 'ramda';
import { BRANCH_ADMIN_PRIVILEGE_OPTION } from '../../../constants';

/**
 *
 * @param branchList
 * @param values
 * @returns {*}
 */
function getDisabledBranches(branchList, values) {
    const existingBranchIds = R.map(R.prop('id'), values.branches);

    return R.compose(
        R.ifElse(
            R.always(values.isCompanyAdmin),
            R.map(R.assoc('privileges', [BRANCH_ADMIN_PRIVILEGE_OPTION])),
            R.identity
        ),
        R.values,
        R.omit(existingBranchIds),
        R.indexBy(R.prop('id'))
    )(branchList);
}

export default getDisabledBranches;
