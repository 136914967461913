/**
 * Effectively, we want to disable fields on the
 * - Disabled User Page
 * - Enable User Page, but only when the field is falsey
 * - Edit User Page, but only when the field is falsey
 * Never on the Add user page
 * @param hasUser
 * @param field
 * @param values
 * @returns {boolean}
 */
function isFieldDisabled(hasUser, field, values) {
    if (hasUser) {
        if (values.isFormDisabled) {
            return true;
        }
        return !!values[field];
    }
    return false;
}

export default isFieldDisabled;
