import React, { useState } from 'react';
import useSaveWorkQueueFilterMutation from '../hooks/useSaveWorkQueueFilterMutation';
import { useFormikContext } from 'formik';
import { Button, FormControl, FormGroup, InputGroup } from 'react-bootstrap';
import { transformReactFilterToSave } from '../workQueue.utils';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import SessionStorageService from 'App/services/SessionStorageService';
import * as R from 'ramda';

const SaveFilterForm = () => {
    const saveFilterMutation = useSaveWorkQueueFilterMutation();
    const [filterName, setFilterName] = useState('');
    const isWorkQueueFiltersEnabled = useIsPermitted(PERMISSION_TYPES.ENABLE_WORK_QUEUE_FILTERS);

    const {
        values: { filterParams },
        setFieldValue,
    } = useFormikContext();

    const saveFilter = (e) => {
        e.stopPropagation();
        saveFilterMutation
            .mutateAsync({ filter: transformReactFilterToSave(filterParams), name: filterName })
            .then((res) => {
                setFilterName('');
                if (isWorkQueueFiltersEnabled) {
                    setFieldValue('activeSavedFilter', res && res[res.length - 1]?.id);
                    SessionStorageService.setOnUser('workQueue_activeSavedFilter', res[res.length - 1]?.id);
                }
            });
    };

    const onSetFilterName = (e) => {
        if (e.target.value?.length <= 20) {
            setFilterName(e.target.value);
        }
    };

    return (
        <div className="filter form-group">
            <FormGroup controlId="save-filter" bsSize="small">
                <InputGroup>
                    <FormControl
                        title="Filter Name"
                        aria-label="Filter Name"
                        type="text"
                        value={filterName}
                        placeholder="Filter name"
                        onChange={onSetFilterName}
                    />
                    <InputGroup.Button>
                        <Button
                            bsStyle="primary"
                            bsSize="small"
                            disabled={!filterName || R.pipe(R.trim, R.isEmpty)(filterName)}
                            onClick={saveFilter}
                        >
                            Save Filter
                        </Button>
                    </InputGroup.Button>
                </InputGroup>
            </FormGroup>
        </div>
    );
};

export default SaveFilterForm;
