import React, { useMemo } from 'react';
import { USER_PRIVILEGE_OPTIONS } from '../../../constants';
import Select from 'react-select';
import { useFormikContext } from 'formik';
import * as R from 'ramda';
import ErrorMessage from 'App/common/formik/ErrorMessage';
import PropTypes from 'prop-types';

const propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.string.isRequired,
            isEnabled: PropTypes.bool.isRequired,
            name: PropTypes.string.isRequired,
        }),
    }).isRequired,
};

const defaultProps = {};

function PermissionsCell({ row }) {
    const { values, setValues } = useFormikContext();
    const { original } = row;
    const { id, isEnabled, name } = original;
    const { branches } = values;

    /**
     * The table branches and the user branches are two different lists.
     * Get the branch and index from the user in Formik so we can make changes.
     */
    const [branch, index] = useMemo(() => {
        const idx = R.findIndex(R.propEq(id, 'id'))(branches);
        return [branches[idx], idx];
    }, [branches, id]);

    const label = `Permissions for ${name}`;

    return (
        <div>
            <Select
                disabled={values.isFormDisabled}
                classNamePrefix="react-select"
                className="react-select"
                isMulti
                aria-label={label}
                isDisabled={!isEnabled}
                options={USER_PRIVILEGE_OPTIONS}
                value={branch?.privileges || []}
                onChange={(privs) => {
                    R.compose(
                        setValues,
                        R.ifElse(
                            R.always(values.applyToAll),
                            R.over(R.lensProp('branches'), R.map(R.assoc('privileges', privs))),
                            R.set(R.lensPath(['branches', index, 'privileges']), privs)
                        )
                    )(values);
                }}
            />
            <ErrorMessage name={`branches.${index}.privileges`} />
        </div>
    );
}

PermissionsCell.propTypes = propTypes;
PermissionsCell.defaultProps = defaultProps;

export default PermissionsCell;
