import React from 'react';
import ActionCell from 'App/components/styles/ActionCell';
import { generatePath, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { enableUserRoute } from 'App/common/routeLookup';

const propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

const defaultProps = {};

function DisabledUserActionsCell({ row }) {
    const user = row.original;

    return (
        <ActionCell>
            <Link to={generatePath(enableUserRoute, { userId: user.id })}>Enable</Link>
        </ActionCell>
    );
}

DisabledUserActionsCell.propTypes = propTypes;
DisabledUserActionsCell.defaultProps = defaultProps;

export default DisabledUserActionsCell;
