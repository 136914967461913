import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FaInfoCircle, FaPencilAlt, FaPlus, FaTimes } from 'react-icons/fa';

import { formatDate } from 'App/components/PatientProfile/PatientProfile.utils';
import { DatePickerField } from 'Lib/form/DatePickerField';
import { NumberField } from 'Lib/form/NumberField';
import { TextField } from 'Lib/form/TextField';

import CommonPatientFieldWrapper from '../CommonPatientFieldWrapper';
import PhysicianTypeaheadField from './PhysicianTypeaheadField';
import RxFillForm from './RxFillForm';
import RxNumberEndModal from './RxNumberEndModal';

const RxBorderContainer = styled.div`
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 5px;
`;

const ButtonContainer = styled.div`
    visibility: hidden;
`;

const RxNumberFormAreaContainer = styled.div`
    display: flex;

    &:hover ${ButtonContainer} {
        visibility: visible;
    }
`;

const RxNumberForm = ({ fieldPrefix, onClickRemove }) => {
    const { values, initialValues, setFieldValue } = useFormikContext();
    const rxValues = get(values, fieldPrefix);
    const initialRxValues = get(initialValues, fieldPrefix);
    const rxFills = rxValues.rxFill;
    const [endModalOpen, setEndModalOpen] = useState(false);
    const isNew = !values.patientMedication.id;

    return (
        <RxBorderContainer>
            <RxNumberFormAreaContainer>
                <div style={{ flexGrow: 1 }}>
                    <Row>
                        <CommonPatientFieldWrapper fieldName="rxNumber">
                            {(commonProps) => (
                                <Col md={2}>
                                    <TextField
                                        {...commonProps}
                                        fieldName={`${fieldPrefix}.rxNumber`}
                                        label="Rx Number"
                                        placeholder="Rx Number"
                                        disabled={commonProps.disabled || (!isNew && !!initialRxValues?.rxNumber)}
                                    />
                                </Col>
                            )}
                        </CommonPatientFieldWrapper>
                        <CommonPatientFieldWrapper fieldName="rxStrength">
                            {(commonProps) => (
                                <Col md={2}>
                                    <TextField
                                        {...commonProps}
                                        fieldName={`${fieldPrefix}.strength`}
                                        label="Rx Strength"
                                        placeholder="Rx Strength"
                                        disabled={commonProps.disabled || (!isNew && !!initialRxValues?.strength)}
                                    />
                                </Col>
                            )}
                        </CommonPatientFieldWrapper>
                        <CommonPatientFieldWrapper fieldName="refillsAllowed">
                            {(commonProps) => (
                                <Col md={2}>
                                    <NumberField
                                        {...commonProps}
                                        fieldName={`${fieldPrefix}.refillsAuthorized`}
                                        label="Refills Allowed"
                                        placeholder="Refills Allowed"
                                        min={0}
                                        max={99}
                                        disabled={
                                            commonProps.disabled || (!isNew && initialRxValues?.refillsAuthorized > 0)
                                        }
                                    />
                                </Col>
                            )}
                        </CommonPatientFieldWrapper>
                        <CommonPatientFieldWrapper fieldName="rxPrescriber">
                            {(commonProps) => (
                                <Col md={3}>
                                    <PhysicianTypeaheadField {...commonProps} fieldPrefix={fieldPrefix} />
                                </Col>
                            )}
                        </CommonPatientFieldWrapper>
                        {!rxValues.end && rxValues.id && (
                            <Col md={3} style={{ marginTop: 25 }}>
                                <Button block onClick={() => setEndModalOpen(true)}>
                                    End Rx Number
                                </Button>
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <CommonPatientFieldWrapper fieldName="rxNumberWrittenDate">
                            {(commonProps) => (
                                <Col md={3}>
                                    <DatePickerField
                                        {...commonProps}
                                        fieldName={`${fieldPrefix}.start`}
                                        label="Rx Number Written Date"
                                        placeholder="YYYY/MM/DD"
                                        maxDate={dayjs().toDate()}
                                        disabled={commonProps.disabled || (!isNew && !!initialRxValues?.start)}
                                    />
                                </Col>
                            )}
                        </CommonPatientFieldWrapper>
                        <CommonPatientFieldWrapper fieldName="rxNumberExpirationDate">
                            {(commonProps) => (
                                <Col md={3}>
                                    <DatePickerField
                                        {...commonProps}
                                        fieldName={`${fieldPrefix}.fillExpiration`}
                                        label="Rx Number Exp. Date"
                                        placeholder="YYYY/MM/DD"
                                        disabled={commonProps.disabled || (!isNew && !!initialRxValues?.fillExpiration)}
                                    />
                                </Col>
                            )}
                        </CommonPatientFieldWrapper>
                    </Row>
                    {rxValues.end && (
                        <div className="global-info-message">
                            <FaInfoCircle className="fa-fw" />
                            <span>
                                Ending prescription because of {rxValues.endReason}, on {formatDate(rxValues.end)}
                            </span>
                            {!initialRxValues.end && (
                                <>
                                    <Button bsStyle="link" onClick={() => setEndModalOpen(true)}>
                                        <FaPencilAlt /> Edit
                                    </Button>
                                    <Button bsStyle="link" onClick={() => setFieldValue(fieldPrefix, initialRxValues)}>
                                        <FaTimes /> Cancel Prescription End
                                    </Button>
                                </>
                            )}
                        </div>
                    )}
                </div>

                <ButtonContainer>
                    <Button
                        bsStyle="link"
                        style={{ visibility: get(values, `${fieldPrefix}.id`) ? 'hidden' : undefined }}
                        onClick={onClickRemove}
                        aria-label="Remove Rx Number"
                    >
                        <FaTimes />
                    </Button>
                </ButtonContainer>
            </RxNumberFormAreaContainer>

            {rxFills?.length > 0 && (
                <RxBorderContainer>
                    {rxFills.map((rxFill, index) => (
                        <RxFillForm
                            key={rxFill.id || `index-${index}`}
                            fieldPrefix={`${fieldPrefix}.rxFill.[${index}]`}
                            onClickRemove={() => {
                                rxFills.splice(index, 1);
                                setFieldValue(`${fieldPrefix}.rxFill`, rxFills);
                            }}
                        />
                    ))}
                </RxBorderContainer>
            )}

            <Button
                bsStyle="link"
                disabled={rxValues.refillsAuthorized < rxFills?.length}
                onClick={() => {
                    const newIndex = rxFills?.length || 0;
                    setFieldValue(`${fieldPrefix}.rxFill.[${newIndex}]`, {});
                }}
            >
                <FaPlus /> Add Fill
            </Button>

            {endModalOpen && (
                <RxNumberEndModal
                    existingRxNumber={rxValues}
                    onClose={() => setEndModalOpen(false)}
                    onSubmit={(modalValues) => {
                        setFieldValue(fieldPrefix, modalValues.rxNumber);
                    }}
                />
            )}
        </RxBorderContainer>
    );
};

RxNumberForm.propTypes = {
    fieldPrefix: PropTypes.string.isRequired,
    onClickRemove: PropTypes.func.isRequired,
};

export default RxNumberForm;
