import { useQuery } from 'react-query';
import CompanyService from 'App/services/CompanyService';

function useFetchCompanyUserRoles(userId, disabledOnly) {
    return useQuery(['company', 'users', userId, 'roles'], {
        queryFn: () => (disabledOnly ? Promise.resolve([]) : CompanyService.getCompanyUserRoles(userId)),
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchCompanyUserRoles;
