import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { useUserContext } from 'App/contexts/UserContext';
import { saveEhrNote } from 'App/services/AMSService';
import { queryClient } from 'Lib/queryClient';
import { branchSettingsPropType, patientPropType } from 'Lib/types';
import FormButtonGroup from '../../styles/FormButtonGroup';
import useFetchMedicationAssociations from '../hooks/useFetchMedicationAssociations';
import { activityPropType, questionPropType } from '../types';
import EhrEncounterPrescriberContent from './EhrEncounterPrescriberContent';
import EhrEncounterReviewContent from './EhrEncounterReviewContent';
import EhrEncounterVisitContent from './EhrEncounterVisitContent';

const ehrModalAction = Object.freeze({
    review: 'review',
    associateVisit: 'associateVisit',
    associatePrescriber: 'associatePrescriber',
});

const EhrEncounterNoteModal = ({ activity, patient, allQuestions, branchSettings, onClose, onComplete }) => {
    const { values, setFieldTouched, validateForm, isValid } = useFormikContext();
    const user = useUserContext();
    const { data: medicationAssociations } = useFetchMedicationAssociations(patient.id, activity.id);
    const medicationAssociationsList = !medicationAssociations ? [] : Object.values(medicationAssociations);

    const [modalState, setModalState] = useState(ehrModalAction.review);
    const modalStateToTitle = {
        [ehrModalAction.review]: activity.name,
        [ehrModalAction.associateVisit]: 'Associate Visit',
        [ehrModalAction.associatePrescriber]: 'Provider',
    };
    const modalStateToComponent = {
        [ehrModalAction.review]: (
            <EhrEncounterReviewContent
                patient={patient}
                allQuestions={allQuestions}
                branchSettings={branchSettings}
                medicationAssociationsList={medicationAssociationsList}
            />
        ),
        [ehrModalAction.associateVisit]: <EhrEncounterVisitContent patient={patient} />,
        [ehrModalAction.associatePrescriber]: (
            <EhrEncounterPrescriberContent medicationAssociationsList={medicationAssociationsList} />
        ),
    };
    const requireVisit = branchSettings.ehrNoteRequiresVisit;
    const requirePrescriber =
        branchSettings.redoxNoteRequiresPrescriber && branchSettings.redoxNotePrescriberType === 'prescriber';

    const availableModalStates = [ehrModalAction.review];
    if (requireVisit) {
        availableModalStates.push(ehrModalAction.associateVisit);
    }
    if (requirePrescriber) {
        availableModalStates.push(ehrModalAction.associatePrescriber);
    }
    const currentStateIndex = availableModalStates.findIndex((value) => value === modalState);

    const handleGoBack = () => {
        if (currentStateIndex === 0) {
            onClose();
        }
        setModalState(availableModalStates[currentStateIndex - 1]);
        setImmediate(() => validateForm());
    };

    async function handleSubmitButton(sendToEhr) {
        if (!isValid) {
            return;
        }
        if (requireVisit && modalState === ehrModalAction.review) {
            setModalState(ehrModalAction.associateVisit);
            setFieldTouched('associatedVisit');
            setImmediate(() => validateForm());
        } else if (requirePrescriber && modalState !== ehrModalAction.associatePrescriber) {
            setModalState(ehrModalAction.associatePrescriber);
            setFieldTouched('associatedPrescriber');
            setImmediate(() => validateForm());
        } else {
            const messageHtml = `<div>${document.getElementsByClassName('modal-header')[0].outerHTML}${
                document.getElementsByClassName('modal-body')[0].outerHTML
            }</div>`;
            const payload = {
                message: messageHtml,
                ehrProviderId: user.user_attributes.ehrProviderId,
                visitId: values.associatedVisit?.visitId,
                facilityId: values.associatedVisit?.facilityId,
                prescriberId: values.associatedPrescriber?.id,
                sendToEhr,
            };
            await onComplete();
            await saveEhrNote(activity.id, payload);
            queryClient.invalidateQueries(['activity', patient.id, activity.id]);
            onClose();
        }
    }

    return (
        <Modal show>
            <Modal.Header>
                <Modal.Title>{modalStateToTitle[modalState]}</Modal.Title>
            </Modal.Header>

            <Modal.Body id="ehr-note-body">{modalStateToComponent[modalState]}</Modal.Body>

            <Modal.Footer>
                <FormButtonGroup>
                    <Button onClick={() => handleSubmitButton(false)}>
                        <span className="text-danger">Don&apos;t Send to EHR</span>
                    </Button>

                    <div style={{ flexGrow: 1 }} />

                    <Button onClick={handleGoBack}>Go Back</Button>
                    <Button id="ehr-note-send-btn" bsStyle="primary" onClick={() => handleSubmitButton(true)}>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {currentStateIndex === availableModalStates.length - 1
                            ? 'Send to EHR'
                            : modalState === ehrModalAction.associateVisit && requirePrescriber
                            ? 'Select Prescriber'
                            : 'Next'}
                    </Button>
                </FormButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};

EhrEncounterNoteModal.propTypes = {
    activity: activityPropType.isRequired,
    patient: patientPropType.isRequired,
    allQuestions: PropTypes.objectOf(questionPropType).isRequired,
    branchSettings: branchSettingsPropType.isRequired,
    onClose: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
};

export default EhrEncounterNoteModal;
