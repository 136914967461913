import dayjs from 'dayjs';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';

import TherigyErrorBoundary from 'App/components/errors/TherigyErrorBoundary';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import { updatePhysician } from 'App/services/PhysicianService';
import CustomMenuField from 'Lib/form/CustomMenu';
import SelectField from 'Lib/form/SelectField';
import SubmitButton from 'Lib/form/SubmitButton';
import { TextField } from 'Lib/form/TextField';
import { TextareaField } from 'Lib/form/TextareaField';

import Collapse from 'App/components/Collapse';
import { daysOfWeek, states } from '../../constants';
import useFetchPhysician from '../../hooks/useFetchPhysician';
import { PhoneField } from '../PhoneField';
import PhysicianAvailability from './EditPhysicianModal/PhysicianAvailability';

const EditPhysicianModal = ({ onClose, existingPhysicianId }) => {
    const { data: existingPhysician, isLoading } = useFetchPhysician(existingPhysicianId);

    if (isLoading) {
        return null;
    }

    return (
        <Modal show bsSize="lg" onHide={onClose}>
            <TherigyErrorBoundary>
                <Modal.Header>
                    <Modal.Title>Create/Update Prescriber</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={{
                        ...existingPhysician,
                        specialty: existingPhysician?.specialty ? { valueName: existingPhysician.specialty } : null,
                        physician_state: existingPhysician
                            ? states.find((s) => s.value === existingPhysician.physician_state)
                            : null,
                        notes: existingPhysician?.notes || '',
                        availability: daysOfWeek.reduce((agg, day) => {
                            const existingAvailability = existingPhysician?.availability.find(
                                (avail) => avail.physician_best_day_to_call === day
                            );
                            const availabilityValues = {
                                enabled: !!existingAvailability,
                                startTime: dayjs()
                                    .hour(
                                        existingAvailability
                                            ? Number(existingAvailability.start_hour) +
                                                  (existingAvailability.start_time === 'pm' ? 12 : 0)
                                            : 9
                                    )
                                    .minute(existingAvailability?.start_minute || 0),
                                endTime: dayjs()
                                    .hour(
                                        existingAvailability
                                            ? Number(existingAvailability?.end_hour) +
                                                  (existingAvailability?.end_time === 'pm' ? 12 : 0)
                                            : 17
                                    )
                                    .minute(existingAvailability?.end_minute || 0),
                            };
                            return { ...agg, [day]: availabilityValues };
                        }, {}),
                    }}
                    onSubmit={async (values) => {
                        const {
                            physician_first_name: firstName,
                            physician_last_name: lastName,
                            physician_fax_number: faxNumber,
                            physician_phone_number: officeNumber,
                            physician_address: address1,
                            physician_address_2: address2,
                            physician_city: city,
                            physician_state: state,
                            physician_zip_code: zip,
                            physician_contact_person_first_name: contactFirstName,
                            physician_contact_person_last_name: contactLastName,
                            physician_contact_person_fax_number: contactFax,
                            physician_contact_person_phone_number: contactPhone,
                            physician_group_id: groupId,
                            ...restValues
                        } = values;
                        const submissionValues = {
                            ...restValues,
                            first_name: firstName,
                            last_name: lastName,
                            fax_number: faxNumber,
                            office_number: officeNumber,
                            address_1: address1,
                            address_2: address2,
                            city,
                            state: state?.value || '',
                            zip,
                            contact_first_name: contactFirstName,
                            contact_last_name: contactLastName,
                            contact_fax: contactFax,
                            contact_phone: contactPhone,
                            group_id: groupId,
                            specialty: values.specialty?.valueName,
                            availability: Object.fromEntries(
                                Object.entries(values.availability).map(([key, avail]) => [
                                    key,
                                    {
                                        enabled: avail.enabled || '',
                                        start_hour: avail.startTime.format('hh'),
                                        start_minute: avail.startTime.format('mm'),
                                        start_time: avail.startTime.format('a'),
                                        end_hour: avail.endTime.format('hh'),
                                        end_minute: avail.endTime.format('mm'),
                                        end_time: avail.endTime.format('a'),
                                    },
                                ])
                            ),
                        };
                        await updatePhysician(submissionValues);
                        onClose();
                    }}
                >
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Modal.Body>
                                <Row>
                                    <Col md={7}>
                                        <Row>
                                            <Col md={6}>
                                                <TextField
                                                    fieldName="physician_first_name"
                                                    label="First Name"
                                                    required
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <TextField fieldName="physician_last_name" label="Last Name" required />
                                            </Col>
                                            <Col md={6}>
                                                <PhoneField fieldName="physician_fax_number" label="Fax Number:" />
                                            </Col>
                                            <Col md={6}>
                                                <PhoneField fieldName="physician_phone_number" label="Office Number:" />
                                            </Col>
                                        </Row>
                                        <CustomMenuField
                                            fieldName="specialty"
                                            label="Specialty:"
                                            menuName="physician_specialty"
                                        />
                                        <TextField fieldName="practice_name" label="Practice Name:" />
                                        <TextField fieldName="physician_address" label="Address:" />
                                        <TextField fieldName="physician_address_2" label="Address 2:" />
                                        <Row>
                                            <Col md={4}>
                                                <TextField fieldName="physician_city" label="City:" />
                                            </Col>
                                            <Col md={4}>
                                                <SelectField
                                                    fieldName="physician_state"
                                                    label="State:"
                                                    options={states}
                                                    isClearable
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <TextField fieldName="physician_zip_code" label="Zip:" />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={5}>
                                        <TextField fieldName="npi" label="Prescriber NPI" required />
                                        <TextField fieldName="physician_group_id" label="Prescriber Group ID:" />
                                        <TextField fieldName="medicaid_id" label="Prescriber Medicaid ID:" />
                                        <TextField fieldName="dea" label="Prescriber DEA ID:" />
                                        <TextField fieldName="state_license" label="Prescriber State License ID:" />
                                        <TextField fieldName="hospital_id" label="Hospital Prescriber ID:" />
                                        <TextField fieldName="pharma_hub_id" label="Pharma HUB Prescriber ID:" />
                                        <TextField
                                            fieldName="hospital_medical_education_id"
                                            label="Prescriber Medical Education ID:"
                                        />
                                        <TextField fieldName="ims_id" label="Prescriber IMS ID:" />
                                    </Col>
                                </Row>

                                <h3>Office Contact</h3>

                                <Row>
                                    <Col md={6}>
                                        <TextField
                                            fieldName="physician_contact_person_first_name"
                                            label="First Name:"
                                        />
                                        <TextField fieldName="physician_contact_person_last_name" label="Last Name:" />
                                        <TextField fieldName="affiliation" label="Prescriber Affiliation:" />
                                    </Col>
                                    <Col md={6}>
                                        <PhoneField
                                            fieldName="physician_contact_person_fax_number"
                                            label="Fax Number:"
                                        />
                                        <PhoneField
                                            fieldName="physician_contact_person_phone_number"
                                            label="Office Number:"
                                        />
                                        <TextareaField fieldName="notes" label="Notes:" />
                                    </Col>
                                </Row>

                                <Collapse collapsedTitle="Show Best Time to Call" defaultShow={!!existingPhysicianId}>
                                    <PhysicianAvailability />
                                </Collapse>
                            </Modal.Body>

                            <Modal.Footer>
                                <FormButtonGroup>
                                    <Button onClick={onClose}>Cancel</Button>
                                    <SubmitButton ignoreDirty>Save</SubmitButton>
                                </FormButtonGroup>
                            </Modal.Footer>
                        </form>
                    )}
                </Formik>
            </TherigyErrorBoundary>
        </Modal>
    );
};

EditPhysicianModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    existingPhysicianId: PropTypes.string,
};
EditPhysicianModal.defaultProps = {
    existingPhysicianId: null,
};

export default EditPhysicianModal;
