import { CheckboxField } from 'Lib/form/CheckboxField';
import { CheckboxMultiField } from 'Lib/form/CheckboxMultiField';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { setWorkQueueFilterGenerator } from '../../workQueue.utils';

const activityTypeOptions = [
    {
        id: 'PCC',
        option: 'PCC',
    },
    {
        id: 'CLI',
        option: 'Clinical',
    },
    {
        id: 'REF',
        option: 'Referral',
    },
];

const customOptions = [
    { id: 'onlyMine', option: 'Updated By Me' },
    { id: 'assignedToMe', option: 'Assigned To Me' },
    {
        id: 'highRisk',
        option: 'High-Risk Patient',
    },
];

const ActivityFilter = () => {
    const {
        values: { filterParams },
        setFieldValue,
    } = useFormikContext();
    const setWorkQueueFilter = setWorkQueueFilterGenerator(setFieldValue);
    const { filters } = filterParams;

    useEffect(() => {
        setWorkQueueFilter(filterParams, 'allTypes', filters?.length === activityTypeOptions.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    return (
        <div className="filter form-group">
            <fieldset>
                <legend className="control-label filter-header">Activity Filter</legend>
                <CheckboxMultiField
                    fieldName="filterParams.filters"
                    options={activityTypeOptions}
                    onChange={(values) => setWorkQueueFilter(filterParams, 'filters', values, true)}
                />
                {customOptions.map((opt) => (
                    <CheckboxField
                        key={opt.id}
                        fieldName={`filterParams.${opt.id}`}
                        label={opt.option}
                        onChange={(e) => setWorkQueueFilter(filterParams, opt.id, e.target.checked, true)}
                    />
                ))}
            </fieldset>
        </div>
    );
};

export default ActivityFilter;
