import PropTypes from 'prop-types';
import React from 'react';

import ToggleSwitch from 'App/components/ToggleSwitch';
import ToggleLabel from 'App/components/ToggleSwitch/ToggleLabel';
import asFormField from './asFormField';
import LabelText from "App/components/ToggleSwitch/LabelText";

const TOGGLE_LABEL_PLACEMENT = Object.freeze({
    right: 'right',
    left: 'left',
});

const propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    className: PropTypes.string,

    label: PropTypes.node,
    labelPlacement: PropTypes.oneOf(Object.values(TOGGLE_LABEL_PLACEMENT)),
    disabled: PropTypes.bool,
};

const defaultProps = {
    className: '',
    value: false,
    label: '',
    labelPlacement: TOGGLE_LABEL_PLACEMENT.right,
    disabled: false,
};

function ToggleSwitchInput({
    name,
    value,
    onChange,
    onBlur,
    className,

    label,
    labelPlacement,
    disabled,
}) {
    return (
        <ToggleLabel>
            {labelPlacement === TOGGLE_LABEL_PLACEMENT.left ? (
                <LabelText placement={TOGGLE_LABEL_PLACEMENT.left}>{label}</LabelText>
            ) : null}

            <ToggleSwitch
                id={name}
                name={name}
                checked={value}
                onChange={onChange}
                onBlur={onBlur}
                className={className}
                disabled={disabled}
            />

            {labelPlacement === TOGGLE_LABEL_PLACEMENT.right ? (
                <LabelText placement={TOGGLE_LABEL_PLACEMENT.right}>{label}</LabelText>
            ) : null}
        </ToggleLabel>
    );
}

ToggleSwitchInput.propTypes = propTypes;

ToggleSwitchInput.defaultProps = defaultProps;

const ToggleSwitchField = asFormField({
    WrappedComponent: ToggleSwitchInput,
    suppressFieldLabel: true,
});

export { TOGGLE_LABEL_PLACEMENT };

export default ToggleSwitchField;
