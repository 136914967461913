import React from 'react';
import Table from 'App/components/Table';
import useFetchCompanyUsers from 'App/hooks/useFetchCompanyUsers';
import BranchesCell from './BranchesCell';
import * as R from 'ramda';
import UserLinkCell from '../../components/UserLinkCell';
import UserActionsCell from './UserActionsCell';
import hasLength from 'App/utils/hasLength';
import propOrDash from 'App/utils/propOrDash';

const propTypes = {};

const defaultProps = {};

function tableStateReducer(state, action, prevState) {
    const { type } = action;
    switch (type) {
        case 'resetFilters':
        case 'resetPage':
        case 'resetSortBy': {
            return prevState;
        }

        case 'setFilter': {
            return R.set(R.lensProp('pageIndex'), 0, state);
        }

        case 'init': {
            return R.set(R.lensProp('sortBy'), [{ id: 'firstName', desc: false }], state);
        }
        default: {
            return state;
        }
    }
}

const columns = [
    {
        Header: 'First Name',
        accessor: propOrDash('firstName'),
        id: 'firstName',
        Cell: UserLinkCell,
    },
    {
        Header: 'Last Name',
        accessor: propOrDash('lastName'),
        id: 'lastName',
        Cell: UserLinkCell,
    },
    {
        Header: 'Email',
        accessor: propOrDash('email'),
        id: 'email',
        Cell: UserLinkCell,
    },
    {
        Header: 'Branches',
        accessor: R.compose(
            R.ifElse(hasLength, R.identity, R.always('--')),
            R.join(', '),
            R.map(R.prop('name')),
            R.sortBy(R.compose(R.toLower, R.prop('name'))),
            R.prop('branches')
        ),
        id: 'branches',
        Cell: BranchesCell,
    },
    {
        Header: 'Actions',
        id: 'actions',
        Cell: UserActionsCell,
    },
];

function UsersTable() {
    const { isLoading, data = [] } = useFetchCompanyUsers();

    return (
        <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            pageSizeOptions={[10, 25, 50]}
            stateReducer={tableStateReducer}
            getKey={R.prop('id')}
        />
    );
}

UsersTable.propTypes = propTypes;
UsersTable.defaultProps = defaultProps;

export default UsersTable;
