import PropTypes from 'prop-types';
import React from 'react';
import { PatternFormat } from 'react-number-format';

import { TextInput } from 'Lib/form/TextField';
import asFormField from 'Lib/form/asFormField';

export const PhoneInput = ({
    name,
    value,
    onChange,
    onBlur,

    placeholder,
    disabled,
}) => {
    return (
        <PatternFormat
            name={name}
            value={value}
            onValueChange={(val) => {
                onChange(val.value);
            }}
            onBlur={onBlur}
            format="(###) ###-####"
            mask="#"
            customInput={TextInput}
            placeholder={placeholder}
            disabled={disabled}
            onKeyDown={(e) => {
                const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Enter'];
                if (!/^[0-9]*$/.test(e.key) && !allowedKeys.includes(e.key) && !e.metaKey) {
                    e.preventDefault();
                }
                if (/^[0-9]*$/.test(e.key) && value.length >= 10 && !e.metaKey) {
                    e.preventDefault();
                }
            }}
        />
    );
};

PhoneInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,

    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
};

PhoneInput.defaultProps = {
    value: '',
    onBlur: null,

    placeholder: '(###) ###-####',
    disabled: false,
};

export const PhoneField = asFormField({ WrappedComponent: PhoneInput });
