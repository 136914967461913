import React from 'react';
import { useLocation } from 'react-router-dom';

import PatientForm from 'App/components/PatientForm/PatientForm';

const AddPatient = () => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const initialData = JSON.parse(searchParams.get('patient'));

    return <PatientForm existingPatient={initialData} />;
};

export default AddPatient;
