import { useCallback, useEffect, useRef } from 'react';

/**
 * Simple way to track if a component is mounted. Useful for preventing updates to a component that has already been
 * removed from the DOM.
 * Ref: https://stackoverflow.com/a/64379060/168005
 * @returns {function => Boolean} - A function that when called will return true if still mounted
 */
function useIsMounted() {
    const isMountedRef = useRef(true);
    const isMounted = useCallback(() => isMountedRef.current, []);

    useEffect(() => {
        return () => {
            isMountedRef.current = false;
            return isMountedRef.current;
        };
    }, []);

    return isMounted;
}

export default useIsMounted;
