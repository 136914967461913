import { useQuery } from 'react-query';
import CompanyService from 'App/services/CompanyService';

function useFetchCompanyUserPrivileges(userId, disabledOnly) {
    return useQuery(['company', 'users', userId, 'privileges'], {
        queryFn: () => (disabledOnly ? Promise.resolve([]) : CompanyService.getCompanyUserPrivileges(userId)),
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchCompanyUserPrivileges;
