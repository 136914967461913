import mapValuesForSubmit from './mapValuesForSubmit';
import CompanyService from 'App/services/CompanyService';
import * as R from 'ramda';

function createUser(values) {
    const { branches, roles, privileges } = mapValuesForSubmit(values);

    return CompanyService.createCompanyUser(R.pick(['firstName', 'lastName', 'email', 'suffix'], values)).then(
        (nexUser) => {
            return CompanyService.updateCompanyUserBranches(nexUser.id, branches)
                .then(() => {
                    return CompanyService.updateCompanyUserRoles(nexUser.id, roles);
                })
                .then(() => {
                    return Promise.all(
                        privileges.map((priv) => {
                            return CompanyService.updateCompanyUserPrivileges(nexUser.id, priv.branchId, priv);
                        })
                    );
                });
        }
    );
}

export default createUser;
