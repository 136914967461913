import { useQuery } from 'react-query';
import { errorHandler } from 'App/utils';
import { getAlertCount } from 'App/services/AlertService';
import isOnLoginPage from 'App/utils/isOnLoginPage';

function useFetchAlertsQuery() {
    const time = 10 * 60 * 1000;

    return useQuery(
        ['user', 'alertCount'],
        () => {
            if (isOnLoginPage()) {
                return 0;
            }
            return getAlertCount();
        },
        {
            onError: errorHandler,
            retry: false,
            cacheTime: time,
            staleTime: time,
            refetchInterval: () => {
                if (isOnLoginPage()) {
                    return false;
                }
                return time;
            },
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchAlertsQuery;
