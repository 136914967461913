import * as R from 'ramda';
import { BRANCH_ADMIN_PRIVILEGE_OPTION } from '../../../constants';

/**
 * Adds the additional Branch ADmin privilege when enabling a row.
 * @param isEnabled
 * @param id
 * @param values
 * @returns {*}
 */
function addBranchAdminToRow(isEnabled, id, values) {
    //prettier-ignore
    return R.ifElse(
        R.allPass([R.prop('isCompanyAdmin'), R.always(isEnabled)]),
        R.over(R.lensProp('branches'), R.compose(
            R.map(
                R.ifElse(R.propEq(id, 'id'),
                    R.assoc('privileges', [BRANCH_ADMIN_PRIVILEGE_OPTION]),
                    R.identity,
                )
            )
        )),
        R.identity,
    )(values)
}

export default R.curry(addBranchAdminToRow);
