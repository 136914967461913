import React, { useMemo } from 'react';
import Table from 'App/components/Table';
import useFetchCompanyBranchesQuery from 'App/hooks/useFetchCompanyBranchesQuery';
import * as R from 'ramda';
import ApplyToAllFilter from './ApplyToAllFilter';
import PermissionsCell from './PermissionsCell';
import EnableCell from './EnableCell';
import EnableAllBranchesFilter from './EnableAllBranchesFilter';
import { useFormikContext } from 'formik';
import hasLength from 'App/utils/hasLength';

const propTypes = {};

const defaultProps = {};

function tableStateReducer(state, action, prevState) {
    const { type } = action;
    switch (type) {
        case 'resetFilters':
        case 'resetPage':
        case 'resetSortBy': {
            return prevState;
        }

        case 'setFilter': {
            return R.assoc('pageIndex', 0, state);
        }

        case 'init': {
            return R.assoc('sortBy', [{ id: 'name', desc: false }], state);
        }
        default: {
            return state;
        }
    }
}

const columns = [
    {
        Header: 'Branch',
        accessor: 'name',
    },
    {
        Header: 'Permissions',
        Filter: ApplyToAllFilter,
        accessor: R.prop('id'),
        Cell: PermissionsCell,
        disableSort: true,
    },
    {
        Header: 'Enable',
        accessor: R.ifElse(R.prop('isEnabled'), R.always('Yes'), R.always('No')),
        Filter: EnableAllBranchesFilter,
        Cell: EnableCell,
        style: {
            width: '15%',
        },
    },
];

function BranchTable() {
    const { isLoading, data: availableBranches } = useFetchCompanyBranchesQuery(true);

    const { values } = useFormikContext();

    const branchesWithEnable = useMemo(() => {
        if (hasLength(availableBranches) && R.is(Array, values.branches)) {
            return R.map((branch) => {
                const isEnabled = R.compose(Boolean, R.find(R.propEq(branch.id, 'id')))(values.branches);
                return R.assoc('isEnabled', isEnabled, branch);
            }, availableBranches);
        }
        return [];
    }, [values.branches, availableBranches]);

    return (
        <Table
            getKey={R.prop('id')}
            isLoading={isLoading}
            data={branchesWithEnable}
            stateReducer={tableStateReducer}
            columns={columns}
        />
    );
}

BranchTable.propTypes = propTypes;
BranchTable.defaultProps = defaultProps;

export default BranchTable;
