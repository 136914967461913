import React, { useState } from 'react';
import * as R from 'ramda';
import { Button, FormGroup } from 'react-bootstrap';
import useFetchUserList from '../hooks/useFetchUserList';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import SelectField from 'Lib/form/SelectField';
import { assignTasks } from 'App/services/AssignmentManagerService';
import { queryClient } from 'Lib/queryClient';
import { updateWorkQueueFilters } from 'App/features/WorkQueue/workQueue.utils';
import convertKeysToSnakeCase from 'App/utils/keyConversion/convertKeysToSnakeCase';

export const AssignmentUserSelect = () => {
    const { data, isLoading } = useFetchUserList();
    const { values, setFieldValue } = useFormikContext();
    const { selectedRows, assignUser, filterParams } = values;
    const isSubmitEnabled = assignUser?.id && !isEmpty(selectedRows);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);

    const assignTasksHandler = () => {
        const activityIds = [];
        for (const activityId of Object.keys(selectedRows)) {
            if (selectedRows[activityId]) {
                activityIds.push(activityId);
            }
        }
        if (activityIds.length === 0) {
            return null;
        }
        setIsFormSubmitting(true);
        const branches = assignUser && assignUser.branches ? assignUser.branches : filterParams.branches;
        const filters = {
            ...filterParams,
            search: convertKeysToSnakeCase(filterParams?.search || {}),
        };
        const params = {
            ...filters,
            assignee: assignUser?.id,
            onlyPcc: assignUser?.pccOnly,
            branches,
            tasks: activityIds,
        };

        return assignTasks(params)
            .then((res) => {
                queryClient.invalidateQueries(['work_queue', JSON.stringify(updateWorkQueueFilters(filterParams))]);
                return res;
            })
            .finally(() => {
                setIsFormSubmitting(false);
                setFieldValue('selectedRows', {});
            });
    };

    return (
        <div className="assignment-manager-form">
            <FormGroup>
                <SelectField
                    isLoading={isLoading}
                    options={data}
                    isClearable
                    label="Select a user to assign activities to"
                    fieldName="assignUser"
                    getOptionValue={R.prop('id')}
                    getOptionLabel={(o) => `${o.firstName} ${o.lastName} (${o.roles.join(', ')})`}
                />
            </FormGroup>
            <Button
                bsClass="btn btn-primary"
                className="assign-tasks-btn"
                disabled={!isSubmitEnabled || isLoading || isFormSubmitting}
                onClick={assignTasksHandler}
            >
                Assign Tasks
            </Button>
        </div>
    );
};
