import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { prop } from 'ramda';
import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FaInfoCircle, FaPencilAlt, FaTimes } from 'react-icons/fa';

import { formatDate } from 'App/components/PatientProfile/PatientProfile.utils.js';
import TherigyInfoMessage from 'App/components/TherigyInfoMessage/TherigyInfoMessage';
import useFetchTherapyCategoriesQuery from 'App/hooks/useFetchTherapyCategoriesQuery';
import { DatePickerField } from 'Lib/form/DatePickerField';
import SelectField from 'Lib/form/SelectField';
import { patientTherapyPropType } from 'Lib/types';

import CommonPatientFieldWrapper from '../CommonPatientFieldWrapper';
import ICD10sTypeaheadField from './ICD10sTypeaheadField';
import TherapeuticCategoryOptInModal from './TherapeuticCategoryOptInModal';
import TherapeuticCategoryOptOutModal from './TherapeuticCategoryOptOutModal';

const EditTherapeuticCategoryModalSection = ({ existingTherapy, allTherapies }) => {
    const { values, initialValues, setFieldValue } = useFormikContext();
    const { data: therapeuticCategories = {}, isTCsLoading } = useFetchTherapyCategoriesQuery();
    const [optOutModalOpen, setOptOutModalOpen] = useState(false);
    const [optInModalOpen, setOptInModalOpen] = useState(false);

    const isTcSelected = !!values.therapy.category?.id;
    const hasOptInStatusChanged = values.therapy.status?.optIn !== initialValues.therapy.status.optIn;

    return (
        <Modal.Body>
            <Row>
                <Col sm={6} md={!isTcSelected ? 6 : 3}>
                    <SelectField
                        fieldName="therapy.category"
                        label="Therapeutic Category"
                        placeholder="Select Therapeutic Category"
                        options={Object.values(therapeuticCategories).sort((a, b) => a.name.localeCompare(b.name))}
                        isLoading={isTCsLoading}
                        getOptionLabel={prop('name')}
                        getOptionValue={prop('id')}
                        isOptionDisabled={(option) => allTherapies.find((therapy) => therapy.id === option.id)}
                        required
                        isDisabled={!!existingTherapy}
                        isClearable
                        onChange={(option) => {
                            setFieldValue('therapy.category', option);
                            setFieldValue('patientMedication.medication', null);
                        }}
                    />
                </Col>

                {!isTcSelected ? (
                    <Col md={12}>
                        <TherigyInfoMessage message="Select a Therapeutic Category to continue." />
                    </Col>
                ) : (
                    <>
                        <CommonPatientFieldWrapper fieldName="icd10Code">
                            {(commonProps) => (
                                <Col md={9}>
                                    <ICD10sTypeaheadField {...commonProps} />
                                </Col>
                            )}
                        </CommonPatientFieldWrapper>
                        {(!existingTherapy || initialValues.therapy.status.optIn) && (
                            <Col md={3}>
                                <DatePickerField
                                    fieldName="therapy.status.start"
                                    label="Care Opt-In Date"
                                    placeholder="YYYY/MM/DD"
                                    maxDate={dayjs().toDate()}
                                />
                            </Col>
                        )}
                        {!hasOptInStatusChanged && (
                            <Col md={3} className="form-group" style={{ marginTop: 25 }}>
                                {initialValues.therapy.status.optIn ? (
                                    <Button block onClick={() => setOptOutModalOpen(true)}>
                                        Opt-out of Care
                                    </Button>
                                ) : (
                                    <Button block onClick={() => setOptInModalOpen(true)}>
                                        Opt-into Care
                                    </Button>
                                )}
                            </Col>
                        )}
                        {(hasOptInStatusChanged || values.therapy.status?.end) && (
                            <Col md={12} className="global-info-message">
                                <FaInfoCircle className="fa-fw" />
                                {!values.therapy.status.end ? (
                                    <>Opting into clinical care on {formatDate(values.therapy.status.start)}</>
                                ) : (
                                    <>
                                        <span>
                                            {hasOptInStatusChanged ? 'Opting' : 'Opted'} out of clinical care because of
                                            &quot;{values.therapy.status.endReason}&quot; on{' '}
                                            {formatDate(values.therapy.status.end)}
                                        </span>
                                        {hasOptInStatusChanged && (
                                            <Button
                                                bsStyle="link"
                                                onClick={() => {
                                                    setOptOutModalOpen(true);
                                                }}
                                            >
                                                <FaPencilAlt /> Edit
                                            </Button>
                                        )}
                                    </>
                                )}
                                {hasOptInStatusChanged && (
                                    <Button
                                        bsStyle="link"
                                        onClick={() => {
                                            setFieldValue('therapy.status', initialValues.therapy.status);
                                        }}
                                    >
                                        <FaTimes /> Reset Changes
                                    </Button>
                                )}
                            </Col>
                        )}
                    </>
                )}
            </Row>
            {optOutModalOpen && (
                <TherapeuticCategoryOptOutModal
                    existingTherapy={values.therapy}
                    onClose={() => setOptOutModalOpen(false)}
                    onSubmit={(modalValues) => {
                        setFieldValue('therapy.status', modalValues.therapy.status);
                    }}
                />
            )}
            {optInModalOpen && (
                <TherapeuticCategoryOptInModal
                    therapyName={values.therapy.name}
                    onClose={() => setOptInModalOpen(false)}
                    onSubmit={(modalValues) => {
                        setFieldValue('therapy.status', modalValues.therapy.status);
                    }}
                />
            )}
        </Modal.Body>
    );
};

EditTherapeuticCategoryModalSection.propTypes = {
    existingTherapy: patientTherapyPropType,
    allTherapies: PropTypes.arrayOf(patientTherapyPropType).isRequired,
};

EditTherapeuticCategoryModalSection.defaultProps = {
    existingTherapy: null,
};

export default EditTherapeuticCategoryModalSection;
