import PropTypes from 'prop-types';

import { rxPropType } from 'Lib/types';

const patientMedicationRxPropType = PropTypes.shape({
    id: PropTypes.string,
    patientId: PropTypes.string,
    medication: PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        groupId: PropTypes.string,
    }),
    status: PropTypes.shape({
        id: PropTypes.number,
        start: PropTypes.string,
        end: PropTypes.string,
        endReason: PropTypes.string,
    }),
    rxs: PropTypes.arrayOf(rxPropType),
});

export default patientMedicationRxPropType;
