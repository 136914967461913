import React from 'react';
import PropTypes from 'prop-types';
import useFetchCompanyBranchQuery from 'App/hooks/useFetchCompanyBranchQuery';
import { Button, Modal } from 'react-bootstrap';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner';
import EditBranchForm from './EditBranchForm';

const propTypes = {
    branchId: PropTypes.string.isRequired,
    onHide: PropTypes.func.isRequired,
};

const defaultProps = {};

function EditBranchModal({ branchId, onHide }) {
    const { isLoading, data } = useFetchCompanyBranchQuery(branchId);

    return (
        <Modal show backdrop="static" onHide={onHide}>
            <Modal.Header>
                <Modal.Title>Edit Branch</Modal.Title>
            </Modal.Header>

            {isLoading ? (
                <>
                    <Modal.Body>
                        <TherigyLoadingSpinner />
                    </Modal.Body>

                    <Modal.Footer>
                        <FormButtonGroup>
                            <Button bsStyle="default" onClick={onHide}>
                                Cancel
                            </Button>
                            <Button bsStyle="primary" disabled>
                                Save
                            </Button>
                        </FormButtonGroup>
                    </Modal.Footer>
                </>
            ) : (
                <EditBranchForm branch={data} onHide={onHide} />
            )}
        </Modal>
    );
}

EditBranchModal.propTypes = propTypes;
EditBranchModal.defaultProps = defaultProps;

export default EditBranchModal;
