import React from 'react';
import UserLinkCell from './UserLinkCell';

function createUserLinkCell(isFormDisabled = false) {
    return (props) => {
        return <UserLinkCell {...props} isFormDisabled={isFormDisabled} />;
    };
}

export default createUserLinkCell;
