import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import AccordionWrapper from 'App/components/AccordionWrapper/AccordionWrapper';
import ManualPageTable from 'App/components/ManualPageTable';
import { formatUtcDate, isoDateOnlyFormat } from 'App/services/DateService';
import SessionStorageService from 'App/services/SessionStorageService';

import useFetchWorkQueueUnassignedMedication from 'App/hooks/useFetchWorkQueueUnassignedMedication';
import useFetchWorkQueueUnassignedMedicationCount from 'App/hooks/useFetchWorkQueueUnassignedMedicationCount';
import { hasLength } from 'App/utils';
import { TextInput } from 'Lib/form/TextField';
import styled from '@emotion/styled';
import UnassignedMedicationsModal from 'App/components/UnassignedMedicationsModal';
import convertKeysToCamelCase from 'App/utils/keyConversion/convertKeysToCamelCase';
import useGetTherapy from 'App/hooks/useGetTherapy';
import AssignCategoryActivityNameCell from '../Cell/AssignCategoryActivityNameCell';

const MarginDiv = styled('div')`
    margin-bottom: 30px;
`;

const PatientIDLinkCell = ({ value, row }) => {
    return <Link to={`/patient/${row.original.id}`}>{value}</Link>;
};
PatientIDLinkCell.propTypes = {
    value: PropTypes.string.isRequired,
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.string,
        }),
    }).isRequired,
};

const filterDefaultState = {
    count: 10,
    page: 1,
    sorting: {
        external_id: 'asc',
    },
    search: {},
};

const UnassignedMedicationTable = () => {
    const [filters, setFilters] = useState(filterDefaultState);
    const [showModal, setShowModal] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const { data: therapy } = useGetTherapy();
    const [lazyFilter, setLazyFilter] = useState(filterDefaultState);
    const {
        data: { data: tableData } = { data: [] },
        isLoading: isSubmitting,
        refetch: refetchUnassignedPatients,
    } = useFetchWorkQueueUnassignedMedication(lazyFilter);
    const { data: { count: tableDataCount } = { count: 0 } } = useFetchWorkQueueUnassignedMedicationCount(lazyFilter);
    const canSort = hasLength(tableData);
    const onFilterChange = useCallback(
        (e) => {
            const prevSearch = { ...filters.search };
            if (e.target.value === '') {
                delete prevSearch[e.target.name];
            } else {
                prevSearch[e.target.name] = e.target.value;
            }
            setFilters((prev) => ({ ...prev, search: prevSearch }));
        },
        [filters.search]
    );

    const unassignedMedications = useMemo(() => {
        let meds = [];
        if (selectedPatient?.unassignedMedications && therapy) {
            meds = selectedPatient.unassignedMedications.map((med) => {
                return {
                    ...med,
                    therapies: therapy[med.medicationName.toLowerCase()].map(convertKeysToCamelCase),
                };
            });
        }
        return {
            unassignedMedications: meds,
            diagnosis: {
                secondary: selectedPatient?.diagnosis?.secondary,
            },
        };
    }, [selectedPatient, therapy]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLazyFilter(filters);
        }, 500);
        return () => clearTimeout(timer);
    }, [filters]);

    const onModalOpen = useCallback((patient) => {
        setSelectedPatient({
            ...patient,
            birthDate: formatUtcDate({ date: patient.birthDate, format: isoDateOnlyFormat }),
        });
        setShowModal(true);
    }, []);

    const hideModal = useCallback(() => {
        refetchUnassignedPatients();
        setSelectedPatient(null);
        setShowModal(false);
    }, [refetchUnassignedPatients]);

    const columns = useMemo(() => {
        return [
            {
                Header: 'Patient ID',
                accessor: 'externalId',
                Cell: PatientIDLinkCell,
                sortName: 'external_id',
                id: 'external_id',
                canSort: true,
                canFilter: true,
                Filter: (
                    <TextInput
                        name="external_id"
                        placeholder="Filter by Id"
                        aria-label="Filter by Id"
                        onChange={onFilterChange}
                        value={filters.search.external_id || ''}
                    />
                ),
            },
            {
                Header: 'Last Name',
                accessor: 'lastName',
                sortName: 'last_name',
                id: 'last_name',
                canSort: true,
                Filter: (
                    <TextInput
                        name="last_name"
                        placeholder="Filter by last name"
                        aria-label="Filter by last name"
                        onChange={onFilterChange}
                        value={filters.search.last_name || ''}
                    />
                ),
            },
            {
                Header: 'First Name',
                accessor: 'firstName',
                sortName: 'first_name',
                id: 'first_name',
                canSort: true,
                Filter: (
                    <TextInput
                        name="first_name"
                        placeholder="Filter by first name"
                        aria-label="Filter by first name"
                        onChange={onFilterChange}
                        value={filters.search.first_name || ''}
                    />
                ),
            },
            {
                Header: 'Medication',
                accessor: ({ unassignedMedications: med }) =>
                    med.map((it) => it.displayName || it.medicationName).join(', '),
                id: 'medication_name',
                sortName: 'medication_name',
                canSort: true,
                Filter: (
                    <TextInput
                        name="medication"
                        placeholder="Filter by medication"
                        aria-label="Filter by medication"
                        onChange={onFilterChange}
                        value={filters.search.medication || ''}
                    />
                ),
            },
            {
                Header: 'Activity Name',
                accessor: 'divisionId',
                Cell: AssignCategoryActivityNameCell,
                onClick: (patient) => {
                    onModalOpen(patient);
                },
                canFilter: false,
                Filter: '',
            },
            {
                Header: 'Patient DOB',
                accessor: ({ birthDate }) => formatUtcDate({ date: birthDate, format: isoDateOnlyFormat }),
                sortName: 'birth_date',
                id: 'birth_date',
                canSort: true,
                Filter: (
                    <TextInput
                        name="birth_date"
                        placeholder="Filter by DOB"
                        aria-label="Filter by DOB"
                        onChange={onFilterChange}
                        value={filters.search.birth_date || ''}
                    />
                ),
            },
            {
                Header: 'Created Date',
                accessor: ({ createdOn }) => formatUtcDate({ date: createdOn, format: isoDateOnlyFormat }),
                sortName: 'created_on',
                id: 'created_on',
                canSort: true,
                Filter: (
                    <TextInput
                        name="created_on"
                        placeholder="Filter by created on"
                        aria-label="Filter by created on"
                        onChange={onFilterChange}
                        value={filters.search.created_on || ''}
                    />
                ),
            },
            {
                Header: 'Profile Updated',
                accessor: ({ updatedOn }) => formatUtcDate({ date: updatedOn, format: isoDateOnlyFormat }),
                sortName: 'updated_on',
                id: 'updated_on',
                canSort: true,
                Filter: (
                    <TextInput
                        name="updated_on"
                        placeholder="Filter by updated on"
                        aria-label="Filter by updated on"
                        onChange={onFilterChange}
                        value={filters.search.updated_on || ''}
                    />
                ),
            },
        ];
    }, [filters.search, onFilterChange, onModalOpen]);

    const onPageChange = ({ pageIndex, pageSize }) => {
        setFilters((prev) => ({
            ...prev,
            count: pageSize,
            page: pageIndex,
        }));
    };

    const onSortChange = (sort) => {
        setFilters((prev) => ({
            ...prev,
            sorting: {
                [sort.sortKey]: sort.sortOrder,
            },
        }));
    };

    if (!tableData.length && isEmpty(lazyFilter.search)) {
        return null;
    }

    return (
        <>
            <AccordionWrapper
                title={`Patients with Unassigned Medications (${tableDataCount})`}
                initialVisible={SessionStorageService.getOnUser('unassignedMedicationActivity-toggle', true)}
                onClick={(newValue) => SessionStorageService.setOnUser('unassignedMedicationActivity-toggle', newValue)}
            >
                <MarginDiv>
                    <ManualPageTable
                        data={tableData}
                        totalRows={tableDataCount}
                        columns={columns}
                        canSort={canSort}
                        isLoading={isSubmitting}
                        onPageChange={onPageChange}
                        onSortChange={onSortChange}
                        pageSizeOptions={[5, 10, 25]}
                        currentPageIndex={filters.count}
                        currentPage={filters.page}
                        initialSortKey="external_id"
                        initialSortOrder="asc"
                    />
                </MarginDiv>
            </AccordionWrapper>
            {showModal && selectedPatient && (
                <UnassignedMedicationsModal
                    patient={selectedPatient}
                    onHide={hideModal}
                    unassignedMedications={unassignedMedications}
                    size="lg"
                />
            )}
        </>
    );
};

export default UnassignedMedicationTable;
