import * as R from 'ramda';
import { BRANCH_ADMIN_PRIVILEGE_OPTION } from '../../../constants';

function getBranchAdminPrivilege(privileges) {
    const hasBranchAdmin = R.any(R.equals(BRANCH_ADMIN_PRIVILEGE_OPTION), privileges || []);

    if (hasBranchAdmin) {
        return privileges;
    }

    return R.append(BRANCH_ADMIN_PRIVILEGE_OPTION, privileges);
}

export default getBranchAdminPrivilege;
