import { useMutation, useQueryClient } from 'react-query';
import CompanyService from 'App/services/CompanyService';

function useFetchCompanyBranchMutation(branchId) {
    const queryClient = useQueryClient();

    return useMutation(['company', 'branches', branchId], {
        mutationFn: (payload) => {
            return CompanyService.updateCompanyBranch(branchId, payload);
        },
        onSuccess: () => {
            return queryClient.refetchQueries(['company', 'branches']);
        },
    });
}

export default useFetchCompanyBranchMutation;
