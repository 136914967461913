import React, { useMemo, useState } from 'react';
import Table from 'App/components/Table';
import * as R from 'ramda';
import useFetchCompanyBranchesQuery from 'App/hooks/useFetchCompanyBranchesQuery';
import EditBranchModal from './EditBranchModal';
import branchActionsRenderer from './branchActionsRenderer';

const propTypes = {};

const defaultProps = {};

function tableStateReducer(state, action, prevState) {
    const { type } = action;
    switch (type) {
        case 'resetFilters':
        case 'resetPage':
        case 'resetSortBy': {
            return prevState;
        }

        case 'setFilter': {
            return R.set(R.lensProp('pageIndex'), 0, state);
        }

        case 'init': {
            return R.set(R.lensProp('sortBy'), [{ id: 'name', desc: false }], state);
        }
        default: {
            return state;
        }
    }
}

function BranchesTable() {
    const { isLoading, data = [] } = useFetchCompanyBranchesQuery();

    const [editBranchModalId, setEditBranchModalId] = useState(null);

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                id: 'name',
            },
            {
                Header: 'Actions',
                id: 'actions',
                Cell: branchActionsRenderer(setEditBranchModalId),
            },
        ],
        [setEditBranchModalId]
    );

    return (
        <>
            <Table
                columns={columns}
                data={data}
                isLoading={isLoading}
                pageSizeOptions={[10, 25, 50]}
                stateReducer={tableStateReducer}
                getKey={R.prop('id')}
            />
            {editBranchModalId ? (
                <EditBranchModal branchId={editBranchModalId} onHide={() => setEditBranchModalId(null)} />
            ) : null}
        </>
    );
}

BranchesTable.propTypes = propTypes;
BranchesTable.defaultProps = defaultProps;

export default BranchesTable;
