import React from 'react';
import Table from 'App/components/Table';
import * as R from 'ramda';
import DisabledUserActionsCell from './DisabledUserActionsCell';
import useFetchDisabledCompanyUsers from 'App/hooks/useFetchDisabledCompanyUsers';
import createUserLinkCell from '../../components/UserLinkCell/createUserLinkCell';
import propOrDash from 'App/utils/propOrDash';

const propTypes = {};

const defaultProps = {};

function tableStateReducer(state, action, prevState) {
    const { type } = action;
    switch (type) {
        case 'resetFilters':
        case 'resetPage':
        case 'resetSortBy': {
            return prevState;
        }

        case 'setFilter': {
            return R.set(R.lensProp('pageIndex'), 0, state);
        }

        case 'init': {
            return R.set(R.lensProp('sortBy'), [{ id: 'firstName', desc: false }], state);
        }
        default: {
            return state;
        }
    }
}

const columns = [
    {
        Header: 'First Name',
        accessor: propOrDash('firstName'),
        id: 'firstName',
        Cell: createUserLinkCell(true),
    },
    {
        Header: 'Last Name',
        accessor: propOrDash('lastName'),
        id: 'lastName',
        Cell: createUserLinkCell(true),
    },
    {
        Header: 'Email',
        accessor: propOrDash('email'),
        id: 'email',
        Cell: createUserLinkCell(true),
    },
    {
        Header: 'Actions',
        id: 'actions',
        Cell: DisabledUserActionsCell,
    },
];

function DisabledUsersTable() {
    const { isLoading, data = [] } = useFetchDisabledCompanyUsers();

    return (
        <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            pageSizeOptions={[10, 25, 50]}
            stateReducer={tableStateReducer}
            getKey={R.prop('id')}
        />
    );
}

DisabledUsersTable.propTypes = propTypes;
DisabledUsersTable.defaultProps = defaultProps;

export default DisabledUsersTable;
