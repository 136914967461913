import React from 'react';
import { Col, Row } from 'react-bootstrap';
import BranchesTable from './BranchesTable';

const propTypes = {};

const defaultProps = {};

function Branches() {
    return (
        <Row data-testid="user-manager-branches-tab-content">
            <Col md={12}>
                <BranchesTable />
            </Col>
        </Row>
    );
}

Branches.propTypes = propTypes;
Branches.defaultProps = defaultProps;

export default Branches;
