function removeNullsFromObject(obj) {
    return Object.fromEntries(
        Object.entries(obj).filter(
            // eslint-disable-next-line no-unused-vars
            ([_key, value]) => {
                return value !== null;
            }
        )
    );
}

export default removeNullsFromObject;
