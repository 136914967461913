import * as R from 'ramda';
import { USER_PRIVILEGE_OPTIONS } from 'App/features/UserManager/constants';

function mapInitialUser(roles, privileges, user, companyBranches, isUserDisabled, isEnabling) {
    let reporting = null;
    const indexedCompanyBranches = R.indexBy(R.prop('id'), companyBranches);

    if (privileges.find(R.propEq(true, 'reportViewer'))) {
        reporting = { value: 'Report Viewer', label: 'Report Viewer' };
    }

    if (privileges.find(R.propEq(true, 'reportBuilder'))) {
        reporting = { value: 'Report Builder', label: 'Report Builder' };
    }

    return R.compose(
        R.assoc('isDisabled', isUserDisabled),
        R.assoc('isFormDisabled', isUserDisabled && !isEnabling),
        R.assoc('applyToAll', false),
        R.assoc('enableAllBranches', false),
        R.assoc('reporting', reporting),
        R.assoc('roles', roles),
        R.over(R.lensProp('isCompanyAdmin'), () => {
            return R.any(R.prop('companyAdmin'), privileges);
        }),
        R.over(R.lensProp('branches'), (branches) => {
            const privilegeOptionKeys = R.compose(R.keys, R.indexBy(R.prop('value')))(USER_PRIVILEGE_OPTIONS);

            return R.compose(
                R.map((branch) => {
                    const initialPrivileges = R.compose(
                        R.ifElse(
                            R.identity,
                            R.compose(
                                R.map(([key]) => {
                                    return R.find(R.propEq(key, 'value'), USER_PRIVILEGE_OPTIONS);
                                }),
                                R.filter(R.propEq(true, 1)),
                                R.toPairs,
                                R.pick(privilegeOptionKeys)
                            ),
                            R.always([])
                        ),
                        R.find(R.propEq(branch.id, 'branchId'))
                    )(privileges);

                    return R.assoc('privileges', initialPrivileges, branch);
                }),
                /**
                 * Filter branches on the user to only include active branches.
                 * An inactive branch on a user will prevent changes from being made.
                 */
                R.filter(R.compose(R.has(R.__, indexedCompanyBranches), R.prop('id')))
            )(branches || []);
        })
    )(user);
}

export default mapInitialUser;
