import React, { useState } from 'react';

import SelectField from 'Lib/form/SelectField';
import useFetchICD10Autocomplete from '../../hooks/useFetchICD10Autocomplete';

const ICD10sTypeaheadField = (props) => {
    const [inputValue, setInputValue] = useState('');
    const { data: options = {}, isLoading } = useFetchICD10Autocomplete(inputValue || 'a', 50);
    const optionsList = Object.values(options);

    return (
        <SelectField
            {...props}
            fieldName="therapy.icd10"
            label="ICD-10 Diagnosis Code"
            placeholder="ICD-10 Diagnosis Code"
            isMulti
            options={optionsList}
            isLoading={isLoading}
            getOptionLabel={(option) =>
                option.icd10Details
                    ? `${option.icd10Details.DxCode} - ${option.icd10Details.DxDescShort}`
                    : `${option.code} - ${option.name || option.shortDescription}`
            }
            getOptionValue={(option) => option.id || option.code}
            onInputChange={setInputValue}
            isClearable
        />
    );
};

export default ICD10sTypeaheadField;
