import React, { useMemo } from 'react';
import Page from 'App/components/Page';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import UserForm from '../components/UserForm';
import useFetchCompanyUser from 'App/hooks/useFetchCompanyUser';
import { useParams } from 'react-router-dom';
import useFetchCompanyUserRoles from 'App/hooks/useFetchCompanyUserRoles';
import useFetchCompanyUserPrivileges from 'App/hooks/useFetchCompanyUserPrivileges';
import PropTypes from 'prop-types';
import mapInitialUser from 'App/features/UserManager/EditUser/utils/mapInitialUser';
import useFetchCompanyBranchesQuery from 'App/hooks/useFetchCompanyBranchesQuery';

const propTypes = {
    isUserDisabled: PropTypes.bool,
    isEnabling: PropTypes.bool,
};

const defaultProps = {
    isUserDisabled: false,
    isEnabling: false,
};

function EditUser({ isUserDisabled, isEnabling }) {
    const { userId } = useParams();

    const userQuery = useFetchCompanyUser(userId, isUserDisabled);
    const rolesQuery = useFetchCompanyUserRoles(userId, isUserDisabled);
    const privilegesQuery = useFetchCompanyUserPrivileges(userId, isUserDisabled);
    const companyBranchesQuery = useFetchCompanyBranchesQuery(true);

    const isLoading =
        userQuery.isLoading || rolesQuery.isLoading || privilegesQuery.isLoading || companyBranchesQuery.isLoading;
    const isError = userQuery.isError || rolesQuery.isError || privilegesQuery.isError || companyBranchesQuery.isError;

    const user = useMemo(() => {
        if (isLoading || isError) {
            return null;
        }

        return mapInitialUser(
            rolesQuery.data,
            privilegesQuery.data,
            userQuery.data,
            companyBranchesQuery.data,
            isUserDisabled,
            isEnabling
        );
    }, [
        isLoading,
        isError,
        rolesQuery.data,
        privilegesQuery.data,
        userQuery.data,
        companyBranchesQuery.data,
        isUserDisabled,
        isEnabling,
    ]);

    return (
        <Page
            key={`${isUserDisabled}-${isEnabling}`}
            showPageTitle
            title="Edit User"
            permissionType={PERMISSION_TYPES.USER_MANAGER}
            isLoading={isLoading}
            showNotFound={isError}
        >
            <UserForm user={user} />
        </Page>
    );
}

EditUser.propTypes = propTypes;
EditUser.defaultProps = defaultProps;

export default EditUser;
