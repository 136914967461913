import styled from '@emotion/styled';
import { useFormikContext } from 'formik';
import React from 'react';
import { Col } from 'react-bootstrap';

import { daysOfWeek } from 'App/components/PatientForm/constants';
import { CheckboxField } from 'Lib/form/CheckboxField';
import AvailabilityTimepickerRange from './AvailabilityTimepickerRange';

const PhysicianAvailabilityStyle = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const PhysicianAvailability = () => {
    const { values } = useFormikContext();

    return (
        <PhysicianAvailabilityStyle>
            {daysOfWeek.map((day) => (
                <Col key={day} md={3}>
                    <CheckboxField fieldName={`availability.${day}.enabled`} label={day} />
                    {values.availability[day].enabled && (
                        <AvailabilityTimepickerRange fieldPrefix={`availability.${day}`} />
                    )}
                </Col>
            ))}
        </PhysicianAvailabilityStyle>
    );
};

export default PhysicianAvailability;
