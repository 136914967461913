const BRANCH_ADMIN_PRIVILEGE_OPTION = { value: 'branchAdmin', label: 'Branch Admin' };

const USER_PRIVILEGE_OPTIONS = Object.freeze([
    { value: 'viewPatient', label: 'View Patient' },
    { value: 'addPatient', label: 'Add Patient' },
    { value: 'conductActivities', label: 'Conduct Activities' },
    { value: 'editProtocols', label: 'Edit Company Protocol Settings' },
    { value: 'summaryNotes', label: 'Add/Edit Summary Notes' },
    { value: 'workQueueAssignment', label: 'Assign Tasks in the Work Queue' },
    BRANCH_ADMIN_PRIVILEGE_OPTION,
]);

const DEFAULT_USER_PRIVIILEGES = Object.freeze({
    addPatient: false,
    api: false,
    branchAdmin: false,
    companyAdmin: false,
    conductActivities: false,
    editProtocols: false,
    nursing: false,
    pcc: false,
    reportBuilder: false,
    reportViewer: false,
    summaryNotes: false,
    viewPatient: false,
    viewReports: false,
});

export { BRANCH_ADMIN_PRIVILEGE_OPTION, DEFAULT_USER_PRIVIILEGES, USER_PRIVILEGE_OPTIONS };
