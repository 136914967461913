import React from 'react';
import { useFormikContext } from 'formik';

const ActivityCount = () => {
    const {
        values: {
            result: { workQueueActivityCount },
        },
    } = useFormikContext();

    return (
        <div className="filter-section filter-section-activity-count">
            <span className="filter-section-header control-label">Activity Count</span>
            <div className="activity-count">{workQueueActivityCount.toLocaleString('en-US')}</div>
        </div>
    );
};

export default ActivityCount;
