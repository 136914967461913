import PESService from '../../services/PESService';
import { errorHandler, hasLength, formatPhone, isExpired } from 'App/utils';
import * as yup from 'yup';
import dayjs from 'dayjs';
import patientPrescriptionUpdate from 'App/components/edit-patient/utils/patientPrescriptionUpdate';
import CompanyService from 'App/services/CompanyService';
import toast from 'Lib/toast';

/** @ngInject */
function AddPatientController(
    $filter,
    $http,
    $httpParamSerializerJQLike,
    $q,
    $scope,
    $rootScope,
    $state,
    $stateParams,
    _,
    authService,
    patientOtherConditionService,
    patientOtherMedicationService,
    patientService,
    patientStatusHistoryService,
    rmsService,
    __env
) {
    const ctrl = this;
    const errorPriority = {
        first_name: 1,
        last_name: 2,
        birth_date: 3,
        gender: 4,
        city: 5,
        state: 6,
        zip_code: 7,
    };
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.closeAlert = closeAlert;
    ctrl.isLocked = isLocked;
    ctrl.isRequired = isRequired;
    ctrl.resetForm = resetForm;
    ctrl.submitForm = submitForm;
    ctrl.validationInsuranceErrors = {};
    ctrl.validationOfInsurance = [];
    ctrl.patientPrescriptions = {};
    ctrl.patientPrescriptionsArray = [];
    ctrl.requiredFields = [];
    ctrl.validationOfRxFillNumber = [];

    $scope.$on('addPatientPage_toggleRequiredFields', toggleRequiredFields);
    $scope.$on('addPatientPage_updatePatient', updatePatient);
    $scope.$on('addPatientPage_updatePatientRiskReasons', updatePatientHighRiskReasons);
    $scope.$on('updatePatientLabels', updatePatientLabels);
    $scope.$on('updatePatientReferral', updatePatientReferral);
    $scope.$on('updatePatientInsurance', updatePatientInsurance);
    $scope.$on('emptyPatientLabel', emptyPatientLabel);
    $scope.$on('validateRxFillNumber', validateRxFillNumber);
    $rootScope.$on('addPatientPrescription', addPatientPrescription);
    $rootScope.$on('addRemovePatientICD10', addRemovePatientICD10);

    function $onChanges(changes) {
        if (changes.user) {
            ctrl.user = angular.copy(ctrl.user);
            ctrl.enabled = !ctrl.user.company_permissions.Pdx && ctrl.user.active_branch.Privileges.AddPatient;
        }
    }

    function $onInit() {
        ctrl._ = _;
        ctrl.integration = $stateParams.integration || '';
        ctrl.patient = angular.copy(JSON.parse($stateParams.patient)) || {};
        ctrl.patient.high_risk = ctrl.patient.high_risk || '0';
        ctrl.therapyErrors = [];
        ctrl.validationErrors = [];
        ctrl.patientLabels = [];
        ctrl.emptyPatientLabel = false;
        ctrl.patientReferral = false;
        ctrl.supportEmail = __env.supportEmail;
        CompanyService.getRequiredPatientFields(ctrl.user.active_company.ID).then((res) => {
            ctrl.requiredFields = angular.copy(Object.values(res).map((record) => record.field));
            $scope.$broadcast('updateRequiredFields', ctrl.requiredFields);
            return res;
        });
    }

    /**
     * @summary Handler for closing alerts boxes
     */
    function closeAlert() {
        ctrl.error = undefined;
        ctrl.success = undefined;
    }

    function isLocked(field) {
        if (ctrl.disabledFields && Array.isArray(ctrl.disabledFields)) {
            return ctrl.disabledFields.indexOf(field) > -1;
        }
        return false;
    }

    function isRequired(field) {
        if (ctrl.requiredFields && Array.isArray(ctrl.requiredFields)) {
            return ctrl.requiredFields.indexOf(field) > -1;
        }
        return false;
    }

    function resetForm() {
        ctrl.addPatientForm.$setPristine();
        ctrl.addPatientForm.$setUntouched();
        ctrl.patient = angular.copy(JSON.parse($stateParams.patient)) || angular.copy({});
        ctrl.patientLabels = [];
        ctrl.patientReferral = false;
    }

    function validateInsuranceOnSubmit() {
        ctrl.insuranceKeys = Object.keys(ctrl.patientInsurance);
        const schema = yup.object().shape({
            name: yup.string().nullable().required('Please enter a Plan Name').label('Plan Name'),
            planPatientId: yup.string().nullable().required('Please enter a Patient ID').label('Patient ID'),
            coverageType: yup
                .string()
                .nullable()
                .matches(/\b(?!Select\b)\w+/, 'Please enter a Benefit Coverage Type')
                .required('Please enter a Benefit Coverage Type')
                .label('Benefit Coverage Type'),
            payerSegment: yup
                .string()
                .nullable()
                .matches(/\b(?!Select\b)\w+/, 'Please enter a Payor Type')
                .required('Please enter a Payor Type')
                .label('Payor Type'),
            pharmacyInsuranceBin:
                ctrl.isRequired('bin') && yup.string().nullable().required('Please enter a BIN').label('BIN'),
            pharmacyInsurancePcn:
                ctrl.isRequired('pcn') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a PCN')
                    .required('Please enter a PCN')
                    .label('PCN'),
            pharmacyInsuranceGroupId:
                ctrl.isRequired('rx_group_number') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Rx Group Number')
                    .required('Please enter a Rx Group Number')
                    .label('Rx Group Number'),
            pharmacyInsurancePersonCode:
                ctrl.isRequired('person_code') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Person Code')
                    .required('Please enter a Person Code')
                    .label('Person Code'),
            groupNumber:
                ctrl.isRequired('medical_group_id') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Medical Group ID')
                    .required('Please enter a Medical Group ID')
                    .label('Medical Group ID'),
            isIntegratedHighDeductable:
                ctrl.isRequired('high_deductible_plan') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a High Deductible Plan')
                    .required('Please enter a High Deductible Plan')
                    .label('High Deductible Plan'),
            isPharmacyBenefitsCarveOutFromMedical:
                ctrl.isRequired('coverage_carve_out') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Coverage Carve Out')
                    .required('Please enter a Coverage Carve Out')
                    .label('Coverage Carve Out'),
            coverageEffectiveDate:
                ctrl.isRequired('coverage_effective_date') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Coverage Effective Date')
                    .required('Please enter a Coverage Effective Date')
                    .label('Coverage Effective Date'),
            coverageEndDate:
                ctrl.isRequired('coverage_end_date') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Coverage End Date')
                    .required('Please enter a Coverage End Date')
                    .label('Coverage End Date'),
            deductableAmount:
                ctrl.isRequired('deductible_amount') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Deductible Amount')
                    .required('Please enter a Deductible Amount')
                    .label('Deductible Amount'),
            deductableAmountPaidToDate:
                ctrl.isRequired('deductible_amount_paid_to_date') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Deductible Amount Paid To Date')
                    .required('Please enter a Deductible Amount Paid To Date')
                    .label('Deductible Amount Paid To Date'),
            pharmacyPlanOopMax:
                ctrl.isRequired('out_of_pocket_maximum') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Out Of Pocket Maximum')
                    .required('Please enter a Out Of Pocket Maximum')
                    .label('Out Of Pocket Maximum'),
            pharmacyPlanOopMaxPaidToDate:
                ctrl.isRequired('out_of_pocket_maximum_paid_to_date') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Out Of Pocket Maximum Paid To Date')
                    .required('Please enter a Out Of Pocket Maximum Paid To Date')
                    .label('Out Of Pocket Maximum Paid To Date'),
            helpDeskPhone:
                ctrl.isRequired('help_desk_phone') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Help Desk Phone')
                    .required('Please enter a Help Desk Phone')
                    .label('Help Desk Phone'),
        });

        for (const key of ctrl.insuranceKeys) {
            ctrl.validationInsuranceErrors[key] = ctrl.validationInsuranceErrors[key] || {};
            try {
                schema.validateSync(ctrl.patientInsurance[key], { abortEarly: false });
            } catch (e) {
                if (e.name === 'ValidationError') {
                    for (const error of e.inner) {
                        ctrl.validationInsuranceErrors[key][error.path] = error.errors[0];
                    }
                }
            }
            ctrl.validationOfInsurance = [
                ...new Set(ctrl.validationOfInsurance.concat(Object.values(ctrl.validationInsuranceErrors[key]))),
            ];
        }
        ctrl.validationOfInsurance = ctrl.validationOfInsurance.map((item) => item.replace('Please enter a ', ''));
        $scope.$broadcast('validatePatientInsurance', ctrl.validationInsuranceErrors);
        return ctrl.validationInsuranceErrors;
    }

    function validatePatientLabelsOnSubmit() {
        const schema =
            ctrl.isRequired('labels') &&
            yup.array().of(yup.string()).required('Please select patient labels').label('Labels');

        try {
            schema.validateSync(ctrl.patientLabels, { abortEarly: false });
        } catch (e) {
            if (e.name === 'ValidationError') {
                ctrl.validationLabelsError.push({
                    name: 'labels',
                    required: true,
                });
            }
        }
    }

    function submitForm() {
        if (ctrl.savingPatient) {
            return false;
        }
        ctrl.savingPatient = true;
        ctrl.addPatientForm.$submitted = true;
        ctrl.validationErrors = [];
        ctrl.validationInsuranceErrors = {};
        ctrl.validationOfInsurance = [];
        ctrl.validationLabelsError = [];

        validatePatientLabelsOnSubmit();
        validateInsuranceOnSubmit();

        if (
            ctrl.addPatientForm.$invalid ||
            ctrl.noTherapies ||
            ctrl.therapyErrors.length ||
            ctrl.validationOfInsurance.length ||
            ctrl.validationLabelsError.length
        ) {
            if (ctrl.addPatientForm.$error.required) {
                _.forEach(ctrl.addPatientForm.$error.required, (error) => {
                    // eslint-disable-next-line no-param-reassign
                    error.priority = errorPriority[error.$name] || null;
                    // eslint-disable-next-line no-param-reassign
                    error.required = true;
                    ctrl.validationErrors.push(error);
                });
            }
            ctrl.validationErrors = _.sortBy(ctrl.validationErrors, ['priority']);
            ctrl.savingPatient = false;
            return false;
        }
        return submitPatient()
            .then((res) => {
                ctrl.patient.id = res.id;
                return submitPatientStatusHistory();
            })
            .then(submitPatientOtherMedication)
            .then(submitPatientOtherCondition)
            .then(submitPatientRiskReasons)
            .then(submitPatientTherapies)
            .then(submitPatientPrescriptions)
            .then(submitPatientLabels)
            .then(submitPatientReferral)
            .then(submitPatientIdentifiers)
            .then(submitPatientInsurance)
            .then(submitPatientTherapyICD10)
            .then(() => {
                return patientService.getPrescriptions(ctrl.patient.id);
            })
            .then((res) => {
                return submitReferralManager(res);
            })
            .then(() => {
                return patientService.update(ctrl.patient.id, {});
            })
            .then((res) => {
                $state.go('app.patient', {
                    patientId: ctrl.patient.id,
                    branchId: ctrl.patient.division_id,
                    user: ctrl.user,
                });
                return res;
            })
            .catch((err) => {
                if (err.data && typeof err.data.message === 'string') {
                    toast.error(err.data.message);
                } else {
                    toast.error('An unknown error has occurred.');
                }
                return err;
            })
            .finally(() => {
                ctrl.savingPatient = false;
            });
    }

    function submitPatientIdentifiers() {
        const otherId = ctrl.patient.other_id;
        if (!otherId) {
            return;
        }

        const newIdentifiers = [{ Id: otherId, Type: 'stm_other_id' }];
        return PESService.addIdentifiers(ctrl.patient.id, newIdentifiers)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    }

    function submitPatient() {
        const patient = {
            address_line_1: ctrl.patient.address_line_1,
            address_line_2: ctrl.patient.address_line_2,
            allergy: ctrl.patient.allergy,
            brand: ctrl.integration,
            birth_date: ctrl.patient.birth_date,
            city: ctrl.patient.city,
            country: ctrl.patient.country,
            deceased_date: ctrl.patient.deceased_date,
            disabled: ctrl.patient.disabled,
            ec_first_name: ctrl.patient.ec_first_name,
            ec_last_name: ctrl.patient.ec_last_name,
            ec_relationship: ctrl.patient.ec_relationship,
            ec_telephone: ctrl.patient.ec_telephone,
            ec_mobile_phone: ctrl.patient.ec_mobile_phone,
            email: ctrl.patient.email,
            ethnicity: ctrl.patient.ethnicity,
            external_id: ctrl.patient.external_id,
            first_name: ctrl.patient.first_name,
            gender: ctrl.patient.gender,
            gender_identity: ctrl.patient.gender_identity,
            pronouns: ctrl.patient.pronouns,
            preferred_name: ctrl.patient.preferred_name,
            height: ctrl.patient.height,
            high_risk: ctrl.patient.high_risk,
            home_phone: ctrl.patient.home_phone,
            lactating: ctrl.patient.lactating,
            language_spoken: ctrl.patient.language_spoken,
            last_name: ctrl.patient.last_name,
            marital_status: ctrl.patient.marital_status,
            medical_plan: ctrl.patient.medical_plan,
            middle_name: ctrl.patient.middle_name,
            mobile_phone: ctrl.patient.mobile_phone,
            no_known_allergies: ctrl.patient.no_known_allergies,
            no_known_medical_conditions: ctrl.patient.no_known_medical_conditions,
            other_allergy: ctrl.patient.other_allergy.join(),
            pcc_id: ctrl.patient.pcc_id,
            pcc_send_email: ctrl.patient.pcc_send_email,
            pbm: ctrl.patient.pbm,
            pharmacy: ctrl.patient.pharmacy,
            pharmacy_id: ctrl.patient.pharmacy_id,
            preferred_contact_method: ctrl.patient.preferred_contact_method,
            pregnancy_status: ctrl.patient.pregnancy_status,
            reimbursement_notes: ctrl.patient.reimbursement_notes,
            remote_medical_plan_id: ctrl.patient.remote_medical_plan_id,
            remote_pbm_id: ctrl.patient.remote_pbm_id,
            smoker_status: ctrl.patient.smoker_status,
            ssn_last_four: ctrl.patient.ssn_last_four,
            state: ctrl.patient.state,
            suffix: ctrl.patient.suffix,
            terminally_ill: ctrl.patient.terminally_ill,
            weight: ctrl.patient.weight,
            welcome_packet: ctrl.patient.welcome_packet,
            welcome_packet_returned: ctrl.patient.welcome_packet_returned,
            welcome_packet_sent: ctrl.patient.welcome_packet_sent,
            work_phone: ctrl.patient.work_phone,
            zip: ctrl.patient.zip,
        };

        // Submit patient changes
        return patientService.create(patient);
    }

    function submitPatientInsurance() {
        const insuranceObject = Object.keys(ctrl.patientInsurance);
        if (hasLength(insuranceObject)) {
            ctrl.patientInsuranceArray = insuranceObject.map((item, index) => {
                ctrl.patientInsurance[item].deductableAmount =
                    typeof ctrl.patientInsurance[item].deductableAmount === 'string'
                        ? parseFloat(ctrl.patientInsurance[item].deductableAmount.replace(/,/g, ''))
                        : ctrl.patientInsurance[item].deductableAmount;

                ctrl.patientInsurance[item].deductableAmountPaidToDate =
                    typeof ctrl.patientInsurance[item].deductableAmountPaidToDate === 'string'
                        ? parseFloat(ctrl.patientInsurance[item].deductableAmountPaidToDate.replace(/,/g, ''))
                        : ctrl.patientInsurance[item].deductableAmountPaidToDate;

                ctrl.patientInsurance[item].pharmacyPlanOopMax =
                    typeof ctrl.patientInsurance[item].pharmacyPlanOopMax === 'string'
                        ? parseFloat(ctrl.patientInsurance[item].pharmacyPlanOopMax.replace(/,/g, ''))
                        : ctrl.patientInsurance[item].pharmacyPlanOopMax;

                ctrl.patientInsurance[item].pharmacyPlanOopMaxPaidToDate =
                    typeof ctrl.patientInsurance[item].pharmacyPlanOopMaxPaidToDate === 'string'
                        ? parseFloat(ctrl.patientInsurance[item].pharmacyPlanOopMaxPaidToDate.replace(/,/g, ''))
                        : ctrl.patientInsurance[item].pharmacyPlanOopMaxPaidToDate;
                ctrl.patientInsurance[item].helpDeskPhone = formatPhone(ctrl.patientInsurance[item].helpDeskPhone);
                if (
                    ctrl.patientInsurance[item].coverageEndDate &&
                    isExpired(dayjs(ctrl.patientInsurance[item].coverageEndDate).utc().format('YYYY-MM-DD 23:59:59'))
                ) {
                    ctrl.patientInsurance[item].disabledBy = ctrl.user.id;
                    ctrl.patientInsurance[item].disabledOn = dayjs.utc().format('YYYY/MM/DD H:mm:ss');
                }

                return {
                    ...ctrl.patientInsurance[item],
                    position: index + 1,
                };
            });

            ctrl.patientInsuranceObject = {
                insurance: ctrl.patientInsuranceArray,
            };
            return PESService.addPatientInsurance(ctrl.patient.id, ctrl.patientInsuranceObject).catch((err) => {
                errorHandler(err);
            });
        }
        return $q.when();
    }

    function submitPatientReferral() {
        if (ctrl.patientReferral === true) {
            return PESService.addPatientReferral(ctrl.patient.id)
                .then((res) => {
                    return res;
                })
                .catch((err) => {
                    return err;
                });
        }
        return $q.when();
    }

    function submitPatientRiskReasons() {
        return patientService
            .updateHighRiskReasons(ctrl.patient.id, ctrl.patientHighRiskReasons)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    }

    function submitPatientLabels() {
        const promises = [];

        const patientLabels = [...ctrl.patientLabels];
        patientLabels.forEach((pl) => {
            promises.push(PESService.addPatientLabel(ctrl.patient.id, pl));
        });

        if (promises.length) {
            return $q.all(promises).catch((err) => {
                return err;
            });
        }
        return $q.when();
    }

    function submitReferralManager(currentPatientMedications) {
        const referralPromises = [];

        if (ctrl.patient.referralType === 'each' || ctrl.patient.referralType === 'one') {
            const referralMedications = [];

            // Compare the medications that are saved to the patient to the medications that were added and create a
            // referral from the medications that have been added
            _.forEach(currentPatientMedications, (m) => {
                if (
                    ctrl.patient.medications[m.medication_id] &&
                    ctrl.patient.medications[m.medication_id].medication.newMedication
                ) {
                    const referralMedication = {
                        medication_id: m.medication_id,
                    };
                    if (m.id) {
                        referralMedication.rx_number_id = parseInt(m.id, 10);
                    }

                    referralMedications.push(referralMedication);
                }
            });

            return rmsService.createReferral(ctrl.patient.id, {
                medication_creates_referral: ctrl.patient.referralType === 'each',
                medications: referralMedications,
            });
        }
        if (ctrl.patient.referralType === 'custom') {
            _.map(ctrl.patient.referralConfig, (referrals) => {
                const referralMedications = [];
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-restricted-syntax
                for (const medicationId in referrals) {
                    if (
                        Object.prototype.hasOwnProperty.call(referrals, medicationId) &&
                        referrals[medicationId] === true
                    ) {
                        referralMedications.push({ medication_id: medicationId });
                    }
                }

                referralPromises.push(rmsService.createReferral(ctrl.patient.id, { medications: referralMedications }));
            });

            return $q.all(referralPromises).then((res) => {
                return res;
            });
        }
        return $q.when();
    }

    function submitPatientOtherCondition() {
        const promises = [];

        _.forEach(ctrl.patient.other_condition, (condition) => {
            promises.push(patientOtherConditionService.post(ctrl.patient.id, condition));
        });

        if (promises.length) {
            return $q.all(promises).catch((err) => {
                return err;
            });
        }
        return $q.when();
    }

    function submitPatientOtherMedication() {
        const promises = [];

        _.forEach(ctrl.patient.other_medication, (medication) => {
            if (medication.text) {
                promises.push(patientOtherMedicationService.post(ctrl.patient.id, medication));
            }
        });

        if (promises.length) {
            return $q.all(promises).catch((err) => {
                return err;
            });
        }
        return $q.when();
    }

    function submitPatientStatusHistory() {
        if (ctrl.patient.patient_status) {
            return patientStatusHistoryService.create(ctrl.patient.id, { status: ctrl.patient.patient_status });
        }
        return $q.when();
    }

    function submitPatientTherapies() {
        const post = {
            patientId: ctrl.patient.id,
            original: {},
            current: {
                patientId: ctrl.patient.id,
                medications: ctrl.patient.medications,
                prescriptions: ctrl.patient.prescriptions,
                therapies: ctrl.patient.therapies,
                referralType: ctrl.patient.referralType,
            },
        };
        return $http
            .post(
                '/stm/patients.php',
                $httpParamSerializerJQLike({
                    response: JSON.stringify(post),
                    patientId: ctrl.patient.id,
                    diff: _.differenceWith(post.original, post.current, _.isEqual),
                }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            )
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    }

    function toggleRequiredFields(event, data) {
        ctrl.requiredOnly = data;
    }

    function updatePatient(event, data) {
        _.assignIn(ctrl.patient, data);

        if (_.isEmpty(ctrl.patient.therapies)) {
            ctrl.noTherapies = true;
        } else {
            ctrl.noTherapies = false;
            ctrl.therapyErrors = [];
            _.forEach(ctrl.patient.therapies, (therapy) => {
                const match = _.find(ctrl.patient.medications, (m) => {
                    return m.medication.group_id === therapy.id;
                });

                if (!match && therapy.newTherapy) {
                    ctrl.therapyErrors.push(
                        `You must select <strong>at least one</strong> medication for ${therapy.name}`
                    );
                }
            });
        }
    }

    function updatePatientHighRiskReasons(event, highRiskReasons) {
        ctrl.patientHighRiskReasons = highRiskReasons;
    }

    function updatePatientLabels(event, patientLabels) {
        ctrl.patientLabels = patientLabels;
    }

    function updatePatientReferral(event, patientReferral) {
        ctrl.patientReferral = patientReferral;
    }

    function updatePatientInsurance(event, patientInsurance) {
        ctrl.patientInsurance = patientInsurance;
    }

    function emptyPatientLabel(event, emptyLabel) {
        ctrl.emptyPatientLabel = emptyLabel;
    }

    function validateRxFillNumber(event, validationOfRxFillNumber) {
        ctrl.validationOfRxFillNumber = validationOfRxFillNumber;
    }

    function addPatientPrescription(event, patientPrescriptions) {
        ctrl.patientPrescriptions = patientPrescriptions;
    }

    function submitPatientPrescriptions() {
        return patientPrescriptionUpdate(ctrl.patientPrescriptions, ctrl.patient.id);
    }

    function addRemovePatientICD10(event, patientTherapiesICD10) {
        ctrl.patientTherapiesICD10 = patientTherapiesICD10;
    }

    function submitPatientTherapyICD10() {
        const therapyIds = Object.keys(ctrl.patientTherapiesICD10 || {});
        const promisesICD10 = [];
        for (const therapyId of therapyIds) {
            promisesICD10.push(
                PESService.addRemovePatientICD10(ctrl.patient.id, therapyId, {
                    patientICD10: ctrl.patientTherapiesICD10[therapyId].icd10,
                })
            );
        }
        if (promisesICD10.length) {
            return $q.all(promisesICD10).catch((err) => {
                return err;
            });
        }
        return $q.when();
    }
}

export default AddPatientController;
