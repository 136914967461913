import { pick } from 'lodash';

import isPermitted, { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';
import convertKeysToCamelCase from 'App/utils/keyConversion/convertKeysToCamelCase';
import convertKeysToSnakeCase from 'App/utils/keyConversion/convertKeysToSnakeCase';
import removeNullsFromObject from 'App/utils/removeNullsFromObject';
import { syncSlsPdxPatient } from 'App/services/PdxPatientService';

/** @ngInject */
function PDXFindPatientController(
    _,
    $q,
    $filter,
    $location,
    $state,
    $uibModal,
    NgTableParams,
    medicationsService,
    patientService,
    pdxFindPatientService,
    CSRF_ID_VALUE,
    CSRF_ID_NAME
) {
    const ctrl = this;
    ctrl._ = _;
    ctrl.$onInit = $onInit;
    ctrl.submit = submit;
    ctrl.resetForm = resetForm;
    ctrl.view = view;
    ctrl.migrate = migrate;
    ctrl.validateSearchForm = validateSearchForm;
    ctrl.csrfIdParamName = CSRF_ID_NAME;
    ctrl.csrfIdParamValue = CSRF_ID_VALUE;

    ctrl.NgTableParams = {
        initialParams: {
            sorting: {
                externalId: 'asc',
            },
            count: 20,
        },
        initialSettings: {
            counts: [],
            defaultSort: 'asc',
        },
    };

    /**
     * @summary Initializes the component
     */
    function $onInit() {
        ctrl.tableParams = null;

        ctrl.status = {
            error: null,
            STM: {
                searching: false,
                count: undefined,
            },
            PDX: {
                searching: false,
                count: undefined,
            },
        };
        ctrl.search = {};
        ctrl.searchForm = {};
        ctrl.searchFilters = {};
        ctrl.showTable = false;
        ctrl.isServerlessRouteEnabled = isPermitted(PERMISSION_TYPES.ENABLE_PDX_SLS, ctrl.user);

        // Since all templates are not using angular, the old templates must past the search name via a query param
        const queryParams = $location.search();
        if (
            $state.params.firstName ||
            $state.params.lastName ||
            typeof queryParams.firstName === 'string' ||
            typeof queryParams.lastName === 'string'
        ) {
            ctrl.search.firstName = $state.params.firstName || queryParams.firstName;
            ctrl.search.lastName = $state.params.lastName || queryParams.lastName;
            submit();
        }
    }

    function getPDXPatient(id) {
        ctrl.status.PDX.searching = true;

        return (
            pdxFindPatientService
                .getPDXPatient(id)
                .then((data) => {
                    return data ? createTable([data]) : false;
                })
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line consistent-return
                .catch((err) => {
                    if (err.status === 404) {
                        return createTable([]);
                    }
                    ctrl.status.error = err;
                })
                .finally(() => {
                    ctrl.status.PDX.searching = false;
                })
        );
    }

    /**
     * Validates the search form
     * @param form
     * @returns {boolean}
     */
    function validateSearchForm() {
        ctrl.status.error = null;
        ctrl.searchForm.$setValidity('fieldCount', true);

        if (ctrl.search.externalId) {
            // Reset all other parameters
            ctrl.search = {
                externalId: ctrl.search.externalId,
            };
            return true;
        }

        const fields = _.pickBy(ctrl.search, (v) => {
            return v && v !== '';
        });

        if (_.size(fields) < 2) {
            ctrl.searchForm.$setValidity('fieldCount', false);
            return false;
        }

        ctrl.search = fields;
        return true;
    }

    /**
     * @summary Submits the my search form
     * @description
     * Submits the my search form..
     * @returns {Array|boolean} Resolves with an array of patient objects if successful, false otherwise
     */
    function submit() {
        ctrl.showTable = null;
        ctrl.tableParams = null;

        // Only search by External ID
        if (ctrl.search.externalId) {
            return getPDXPatient(ctrl.search.externalId);
        }

        if (ctrl.searchFilters.pdxFirst) {
            return searchPDX();
        }

        // Standard search routine, STM and then PDX
        return searchSTM().then((data) => {
            if (data.length) {
                ctrl.status.STM.count = data.length;
                return createTable(data);
            }
            return searchPDX();
        });
    }

    function searchSTM() {
        ctrl.status.STM.searching = true;
        const params = angular.copy(ctrl.search);

        // By default only search active patients.
        if (ctrl.searchFilters.showDisabled !== true) {
            params.disabledBy = '';
        }

        // Format the birth date
        if (params.birthDate) {
            params.birthDate = $filter('date')(params.birthDate, 'yyyy/MM/dd');
        }

        return pdxFindPatientService
            .searchSTM(params)
            .then((data) => {
                return data;
            })
            .catch((err) => {
                throw err;
            })
            .finally(() => {
                ctrl.status.STM.searching = false;
            });
    }

    /**
     * Search Pdx endpoint
     */
    function searchPDX() {
        ctrl.status.PDX.searching = true;
        const params = angular.copy(ctrl.search);

        // Break down birthday date
        if (params.birthDate) {
            params.birthYear = $filter('date')(params.birthDate, 'yyyy');
            params.birthMonth = $filter('date')(params.birthDate, 'MM');
            params.birthDay = $filter('date')(params.birthDate, 'dd');
            params.birthDate = undefined;
        }

        // Search only by first letter of the patients gender
        if (params.gender) {
            params.gender = params.gender.substr(0, 1);
        }

        return (
            pdxFindPatientService
                .searchPDX(params)
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line consistent-return
                .then((data) => {
                    if (data) {
                        return createTable(data);
                    }
                })
                .catch((err) => {
                    ctrl.status.error = err;
                })
                .finally(() => {
                    ctrl.status.PDX.searching = false;
                })
        );
    }

    /**
     * Open up the modal to view Patients Details
     * @param patientId
     */
    function view(patientId) {
        $uibModal
            .open({
                component: 'pdxPreViewPatient',
                backdrop: 'static',
                resolve: {
                    patient() {
                        return pdxFindPatientService.getPDXPatient(patientId);
                    },
                },
                size: 'lg',
            })
            .result.then(() => {})
            .catch(() => {});
    }

    /**
     * This function takes patient intended for migration and searches STM to find if similar exist,
     * If similar patient found merge dialog will be opened.
     * @param patientExternalId
     */
    function migrate(patientExternalId) {
        if (ctrl.isServerlessRouteEnabled) {
            syncSlsPdxPatient(patientExternalId).then(findDuplicates);
        } else {
            pdxFindPatientService.getPDXPatient(patientExternalId).then(findDuplicates);
        }

        function findDuplicates(patient) {
            const params = {
                first_name: patient.FirstName,
                last_name: patient.LastName,
                gender: patient.Gender,
                birth_date: $filter('date')(patient.BirthDate, 'yyyy/MM/dd'),
                disabled_by: '',
            };

            return pdxFindPatientService.searchSTM(params).then((res) => {
                return res.length ? foundDuplicate(res, patient) : finishMigration(patient);
            });
        }

        function foundDuplicate(duplicates, patient) {
            const result = [];
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-restricted-syntax, guard-for-in
            for (const d in duplicates) {
                const stmPatient = duplicates[d];

                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line func-names, no-shadow
                (function (stmPatient, patient) {
                    $q.all({
                        medications: medicationsService.getPatientMedications(stmPatient.ID),
                        activities: patientService.getActivities(stmPatient.ID),
                    }).then((data) => {
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-param-reassign
                        stmPatient.activityCount = 0;
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-param-reassign
                        stmPatient.medicationCount = 0;
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-param-reassign
                        stmPatient.PDXExternalId = patient.ExternalId;

                        const { medications } = data;
                        const { activities } = data;

                        if (medications) {
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-param-reassign
                            stmPatient.medicationCount = _.size(medications);
                        }

                        if (activities) {
                            const { activity } = activities;
                            let count = 0;

                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-restricted-syntax, guard-for-in
                            for (const a in activity) {
                                count += activity[a].length;
                            }
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-param-reassign
                            stmPatient.activityCount = count;
                        }

                        result.push(stmPatient);
                    });
                })(stmPatient, patient);
            }
            return migrateModal(result);
        }

        function finishMigration(patient) {
            if (ctrl.isServerlessRouteEnabled) {
                $state.go('app.patient', {
                    patientId: patient.ID,
                });
            } else {
                const patientData = pick(patient, [
                    'FirstName',
                    'MiddleName',
                    'LastName',
                    'BirthDate',
                    'Gender',
                    'AddressLine1',
                    'AddressLine2',
                    'City',
                    'State',
                    'Zip',
                    'Country',
                    'Ethnicity',
                    'MaritalStatus',
                    'LanguageSpoken',
                    'Email',
                    'MobilePhone',
                    'HomePhone',
                    'ExternalId',
                ]);
                const isReactAddEditPatientEnabled = isPermitted(PERMISSION_TYPES.ENABLE_REACT_ADD_EDIT_PATIENT, {});
                const convertedData = isReactAddEditPatientEnabled
                    ? convertKeysToCamelCase(patientData, true)
                    : convertKeysToSnakeCase(patientData, true);

                const stateParams = {
                    patient: JSON.stringify(removeNullsFromObject(convertedData)),
                    locked: ctrl.lockedFields,
                    integration: 'pdx',
                };

                $state.go('app.addPatient', stateParams);
            }
        }
    }

    function migrateModal(patient) {
        $uibModal
            .open({
                component: 'pdxDuplicatePatientResolveModal',
                backdrop: 'static',
                resolve: {
                    patient() {
                        return patient;
                    },
                },
                size: 'lg',
            })
            .result.then((resolve) => {
                // Reflect the change in the search table
                const dataSet = ctrl.NgTableParams.initialSettings.dataset;
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-restricted-syntax
                for (const row in dataSet) {
                    if (resolve.ExternalId === dataSet[row].ExternalId) {
                        dataSet[row].Linked = true;
                        dataSet[row].ID = resolve.ID;
                        dataSet[row].DivisionId = resolve.DivisionId;
                    }
                }
            })
            .catch(() => {});
    }

    /**
     * Responsible to creating table with data
     * @param data array
     * @returns {boolean}
     */
    function createTable(data) {
        if (!data) {
            return false;
        }

        ctrl.NgTableParams.initialSettings.dataset = data;
        ctrl.tableParams = new NgTableParams(ctrl.NgTableParams.initialParams, ctrl.NgTableParams.initialSettings);
        ctrl.showTable = true;

        return true;
    }

    /**
     * @summary Resets the search object
     */
    function resetForm() {
        ctrl.search = {};
        ctrl.searchFilters = {};
        ctrl.searchForm.$setValidity('fieldCount', true);
        ctrl.searchForm.$setPristine();
        ctrl.searchForm.$setUntouched();
    }
}

export default PDXFindPatientController;
