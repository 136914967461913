import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import getPrivileges from './utils/getPrivileges';

/**
 * Gets the privileges that will be used when "All selected branches use the same permissions" is checked.
 */
function usePrivsForAll() {
    const { values } = useFormikContext();

    return useMemo(() => {
        return getPrivileges(values);
    }, [values]);
}

export default usePrivsForAll;
