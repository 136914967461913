import React from 'react';
import { Col, Row } from 'react-bootstrap';
import UsersTable from './UsersTable';
import { Link } from 'react-router-dom';
import { createUserRoute } from 'App/common/routeLookup';

const propTypes = {};

const defaultProps = {};

function Users() {
    return (
        <div data-testid="user-manager-user-tab-content">
            <Row>
                <Col md={12}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            paddingBottom: '1rem',
                        }}
                    >
                        <Link to={createUserRoute} className="btn btn-primary">
                            Add User
                        </Link>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <UsersTable />
                </Col>
            </Row>
        </div>
    );
}

Users.propTypes = propTypes;
Users.defaultProps = defaultProps;

export default Users;
