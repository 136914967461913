import * as R from 'ramda';

/**
 * When toggling "apply to all" on, we need to apply all privs to all branches
 * @param privsForAll - This will be generated by another function and passed in.
 * @param values
 * @returns {*}
 */
function getApplyToAllPrivs(privsForAll, values) {
    return R.compose(
        R.over(
            R.lensProp('branches'),
            //prettier-ignore
            R.ifElse(
                R.always(values.applyToAll),
                R.map(R.assoc('privileges', privsForAll)),
                R.identity)
        )
    )(values);
}

export default R.curry(getApplyToAllPrivs);
